/* global bodymovin */
import React from 'react'
import {connect} from 'react-redux'

import animData from './404.json'
import {initResponseStatus, setResponseStatus} from './redux/actions.responseStatus'

class NotFoundComponent extends React.Component {
  state = {
    animation: null,
    libLoaded: false,
  }

  componentWillMount() {
    this.props.dispatch(setResponseStatus(404))
  }

  componentWillUnmount() {
    this.props.dispatch(initResponseStatus())
    if (this.state.animation) {
      this.state.animation.destroy()
    }
  }

  componentDidMount() {
    const existingScript = document.getElementById('bodymovin-script')

    if (!existingScript) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://cdnjs.cloudflare.com/ajax/libs/bodymovin/4.13.0/bodymovin.min.js'
      script.id = 'bodymovin-script'
      document.body.appendChild(script)

      script.onload = () => {
        this.setState({libLoaded: true})
      }
    }

    if (existingScript) {
      this.setState({libLoaded: true})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.libLoaded && !prevState.libLoaded) {
      this.runAnimation()
    }
  }

  runAnimation = () => {
    if (this.animContainer) {
      const animation = bodymovin.loadAnimation({
        container: this.animContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animData,
      })
      this.setState({animation})
    }
  }

  render() {
    return (
      <div className="not-found row not-found__row">
        <div className="small-12 columns not-found__fill" />
        <div className="small-12 columns">
          <div className="small-12 columns not-found__animContainerParent">
            <div
              className="not-found__animationContainer"
              ref={(elem) => {
                this.animContainer = elem
              }}
            />
          </div>
          <div className="animTextContainer">
            <div className="mainText">Didn't order any pizza? Woops.</div>
            <div className="subText">We'll deliver you back to our home page.</div>
            <a href="/" className="button goHome">
              Go Back Home
            </a>
          </div>
          <div className="small-12 columns not-found__bottomFill" />
        </div>
      </div>
    )
  }
}

NotFoundComponent.displayName = 'NotFoundComponent'

export default connect()(NotFoundComponent)
