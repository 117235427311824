import React from 'react'

import BaseCTAForm from '../common/BaseCTAForm.jsx'

class SectionCTAForm extends BaseCTAForm {
  componentDidMount() {
    this.openCTAForm()
  }

  render() {
    return (
      <div className="cta careerDetails__sections__item__cta clearfix">
        <div
          className="cta__form careerDetails__sections__item__cta__form clearfix"
          id={this.state.formElementId}
        />
      </div>
    )
  }
}

SectionCTAForm.displayName = 'SectionCTAForm'

export default SectionCTAForm
