import * as Types from './actions.types'

export function getCaseStudiesList(params = {}) {
  return {type: Types.CASESTUDIES_LIST_GET, params}
}

export function getCaseStudiesListSuccess(caseStudies) {
  return {type: Types.CASESTUDIES_LIST_GET_SUCCESS, caseStudies}
}

export function getCaseStudiesListFailure(error) {
  return {type: Types.CASESTUDIES_LIST_GET_FAILURE, error}
}

export function getCaseStudy(slug, params = {}) {
  return {type: Types.CASESTUDY_GET, slug, params}
}

export function getCaseStudySuccess(caseStudy) {
  return {type: Types.CASESTUDY_GET_SUCCESS, caseStudy}
}

export function getCaseStudyFailure(error) {
  return {type: Types.CASESTUDY_GET_FAILURE, error}
}
