import uniqueId from 'lodash/uniqueId'

import {NotificationActionTypes, NotificationType} from './actions.notifications.types'

export function create(type, message) {
  return {
    type: NotificationActionTypes.CREATE_NOTIFICATION,
    notification: {
      id: uniqueId(),
      message,
      type,
    },
  }
}

export function createError(message) {
  return {
    type: NotificationActionTypes.CREATE_NOTIFICATION,
    notification: {
      id: uniqueId(),
      message,
      type: NotificationType.error,
    },
  }
}

export function createSuccess(message) {
  return {
    type: NotificationActionTypes.CREATE_NOTIFICATION,
    notification: {
      id: uniqueId(),
      message,
      type: NotificationType.success,
    },
  }
}

export function dismiss(notification) {
  return {
    type: NotificationActionTypes.DISMISS_NOTIFICATION,
    notification,
  }
}
