import * as Types from './actions.types'

export function getPage(slug) {
  return {type: Types.PAGE_GET, slug}
}

export function getPageSuccess(page) {
  return {type: Types.PAGE_GET_SUCCESS, page}
}

export function getPageFailure(error) {
  return {type: Types.PAGE_GET_FAILURE, error}
}
