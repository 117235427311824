import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import settings from '../../../config/settings'
import BasePage from '../common/BasePage.jsx'
import {getPage} from './redux/actions'

class Privacy extends React.Component {
  componentWillMount() {
    this.props.getPage('privacy-policy')
  }

  render() {
    const {page, isFetching, notFound} = this.props

    return (
      <BasePage className="privacyPolicy" page={page} isFetching={isFetching} notFound={notFound}>
        <Helmet>
          <link rel="canonical" href={`${settings.appBaseURL}/privacy-policy`} />
          <meta property="og:url" href={`${settings.appBaseURL}/privacy-policy`} />
        </Helmet>
        {page && (
          <div className="row">
            <div dangerouslySetInnerHTML={{__html: page.body}} className="columns large-12" />
          </div>
        )}
      </BasePage>
    )
  }
}

Privacy.displayName = 'Privacy'

function mapStateToProps(state) {
  return {
    ...state.pages,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getPage}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Privacy)
