import {Grid, withStyles} from '@material-ui/core'
import cx from 'classnames'
import React from 'react'
import {Link} from 'react-router'

import Approvd from '../../assets/approvdLogo.png'
import BaseApp from '../../assets/baseAppLogo.png'
import Arrow from '../../assets/blueArrow.png'
import LazyImage from '../common/LazyImage'

const styles = (theme) => ({
  container: {
    maxWidth: '1500px',
    margin: '0 auto',
    height: 400,
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      height: '860px',
    },
  },
  paragraphText: {
    fontSize: '18px',
  },
  columnOne: {
    paddingRight: '40px',
    paddingLeft: '40px',
    width: '100%',
    margin: 'auto',
    position: 'absolute',
    top: '42%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    [theme.breakpoints.down('sm')]: {
      top: '46%',
    },
  },
  columnTwo: {
    paddingRight: '40px',
    paddingLeft: '40px',
    width: '100%',
    margin: 'auto',
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    [theme.breakpoints.down('sm')]: {
      top: '38%',
    },
  },
  columnThree: {
    paddingRight: '40px',
    paddingLeft: '40px',
    width: '100%',
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    [theme.breakpoints.down('sm')]: {
      top: '35%',
    },
  },
  headerText: {
    paddingTop: 25,
    color: '#28A2D1',
    fontSize: '30px',
    fontWeight: '600',
    marginBottom: '38px',
    marginTop: '22px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px',
    },
  },
  approvedLogo: {
    width: '50%',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  baseAppLogo: {
    width: '20%',
    marginBottom: '20px',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '30%',
      marginTop: '0px',
    },
  },
  exploreButton: {
    borderBottom: '3px solid #009CD9',
    color: 'black',
    paddingBottom: '5px',
  },
})

class Products extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12} md={4}>
          <div className={classes.columnOne}>
            <h5 className={classes.headerText}>BY US / FOR YOU</h5>
            <p className={classes.paragraphText}>
              Meet the two products that we've built for ourselves to better help our customers to
              receive extremely fast results with a high level of communication and transparency.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.columnTwo}>
            <LazyImage className={cx(classes.baseAppLogo)} src={BaseApp} alt="Base App Logo" />{' '}
            <p className={classes.paragraphText}>
              BaseApp is our open-source framework that is the beginning of every project we take
              on. We have created all the basic features, such as user logins, profiles, navigation,
              etc... so you don't have to pay for their development.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.columnThree}>
            <LazyImage className={cx(classes.approvedLogo)} src={Approvd} alt="Approved Logo" />{' '}
            <p className={classes.paragraphText}>
              Building custom software has many details, decisions, and approvals as a marketing
              agency. That's why we built Approvd. It is our approval portal tool that makes that
              process a little easier.
            </p>
            <Link
              type="button"
              target="_blank"
              rel="noopener noreferrer"
              to="https://approvd.io/"
              className={classes.exploreButton}
            >
              Explore Approvd.io &nbsp; <img src={Arrow} style={{paddingLeft: 50}} />
            </Link>
          </div>
        </Grid>
      </Grid>
    )
  }
}

Products.displayName = 'Products'

export default withStyles(styles)(Products)
