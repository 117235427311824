import humps from 'humps'
import forOwn from 'lodash/forOwn'
import get from 'lodash/get'

export function getApiErrors(errorObj) {
  return humps.camelizeKeys(get(errorObj, 'response.data', {}))
}

export function formatApiErrorMessages(errors, messages = [], prefix = '') {
  forOwn(errors, (v, k) => {
    const newPrefix = prefix ? `${prefix}[${formatFieldName(k)}]` : formatFieldName(k)
    if (typeof v === 'string') {
      messages.push(`${newPrefix}: ${v}`)
    }
    if (Array.isArray(v) && v.length) {
      if (typeof v[0] === 'string') {
        messages.push(`${newPrefix}: ` + v.join('; '))
      } else {
        v.forEach((errors, i) => {
          messages = formatApiErrorMessages(errors, messages, `${newPrefix}[${i + 1}]`)
        })
      }
    } else if (typeof v === 'object') {
      messages = formatApiErrorMessages(v, messages, `${newPrefix}`)
    }
  })
  return messages
}

function formatFieldName(name) {
  let result = `${humps.decamelize(name).replace(/_/g, ' ')}`
  result = result.substr(0, 1).toUpperCase() + result.substr(1)
  return result
}
