import settings from '../../../config/settings'
import axios from '../api/axios'

export default class SkillsApi {
  static getSkill(slug) {
    return axios.get(`${settings.apiBaseURL}/skills/${slug}?expand=ctaform`)
  }

  static getList(params) {
    return axios.get(`${settings.apiBaseURL}/skills`, {params})
  }
}
