import {withStyles} from '@material-ui/core'
import cx from 'classnames'
import React from 'react'
import Slider from 'react-slick'

import bitcoin2017 from '../../assets/awards/bitcoin2017.png'
import bitcoin2018 from '../../assets/awards/bitcoin2018.png'
import emerge2015 from '../../assets/awards/emerge2015.png'
import emerge2016 from '../../assets/awards/emerge2016.png'
import emerge2017 from '../../assets/awards/emerge2017.png'
import emerge2018 from '../../assets/awards/emerge2018.png'
import emerge2019 from '../../assets/awards/emerge2019.png'
import emergeAPI2018 from '../../assets/awards/emergeAPI2018.png'
import emergeVisa2018 from '../../assets/awards/emergeVisa2018.png'
import healthHack2019 from '../../assets/awards/healthHack2019.png'
import healthHackMicrosoft2019 from '../../assets/awards/healthHackMicrosoft2019.png'
import money2017 from '../../assets/awards/money2017.png'
import money2018 from '../../assets/awards/money2018.png'
import moneyVisa2017 from '../../assets/awards/moneyVisa2017.png'
import LazyImage from '../common/LazyImage'

const styles = (theme) => ({
  containerBottom: {
    // backgroundColor: '#F2F2F2',
    backgroundColor: 'white',
    height: '350px',
    width: '100%',
    marginTop: '-3px',
    '-webkit-backface-visibility': 'hidden' /* Safari */,
    backfaceVisibility: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '270px',
      marginTop: '-50px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '270px',
      marginTop: '-50px',
      marginBottom: 0,
    },
  },
  text: {
    marginBottom: 55,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 35,
    },
    '& h5': {
      display: 'inline-block',
      color: '#28a2d1',
      fontSize: '40px',
      fontWeight: 'bold',
      textAlign: 'center',
      paddingTop: 50,
      textDecoration: 'none',
      paddingRight: 10,
      [theme.breakpoints.down('xs')]: {
        fontSize: '28px',
      },
    },
    '& h6': {
      display: 'inline-block',
      color: '#28a2d1',
      fontSize: '30px',
      textAlign: 'center',
      paddingTop: 50,
      textDecoration: 'none',
      [theme.breakpoints.down('xs')]: {
        fontSize: '23px',
      },
    },
  },

  imageLarge: {
    maxWidth: '35%',
    margin: 'auto',
    position: 'absolute',
    top: '0%',
    left: '50%',
    transform: 'translate(-50%,100%) scale(2.2)',
    filter: 'grayscale(1)',
    transition: 'all .1s ease-in-out',
    '&:hover': {
      filter: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      filter: 'grayscale(1)',
      transform: 'translate(-50%,0%) scale(2.5)',
      marginTop: '25px',
      '&:hover': {
        filter: 'grayscale(1)',
      },
    },
    [theme.breakpoints.down('xs')]: {
      filter: 'grayscale(1)',
      transform: 'translate(-50%,0%) scale(2.5)',
      marginTop: '45 !important',
      '&:hover': {
        filter: 'grayscale(1)',
      },
    },
  },
})

class Awards extends React.Component {
  render() {
    const {classes} = this.props

    const settings2 = {
      dots: false,
      infinite: true,
      speed: 9000,
      useCSS: true,
      arrows: false,
      autoplaySpeed: 0,
      cssEase: 'linear',
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      rtl: false,
      centerMode: true,
      accessibility: false,
      touchMove: false,
      pauseOnHover: false,
      pauseOnFocus: false,
      pauseOnDotsHover: false,
      responsive: [
        {
          breakpoint: 1366,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            autoplaySpeed: 5000,
            speed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            touchMove: false,
            waitForAnimate: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            pauseOnDotsHover: false,
            swipe: false,
          },
        },
      ],
    }

    return (
      <div>
        <style>{cssstyle}</style>
        <div className={classes.containerBottom}>
          <div className={classes.text}>
            <h5>AWARD</h5>
            <h6> WINNING COMPANY</h6>
          </div>
          <Slider {...settings2}>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge)}
                src={emerge2015}
                alt="Emerge 2015 Award"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge)}
                src={emerge2016}
                alt="Emerge 2016 Award"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge)}
                src={bitcoin2017}
                alt="Bitcoin 2017 Award"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge)}
                src={emerge2017}
                alt="Emerge 2017 Award"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge)}
                src={money2017}
                alt="Money 2017 Award"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge)}
                src={moneyVisa2017}
                alt="Money Visa 2017 Award"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge)}
                src={bitcoin2018}
                alt="Bitcoin 2018 Award"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge)}
                src={emerge2018}
                alt="Emerge 2018 Award"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge)}
                src={emergeAPI2018}
                alt="Emerge API 2018 Award"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge)}
                src={emergeVisa2018}
                alt="Emerge Visa 2018 Award"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge)}
                src={money2018}
                alt="Money 2018 Award"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge)}
                src={emerge2019}
                alt="Emerge 2019 Award"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge)}
                src={healthHack2019}
                alt="Health Hack 2019 Award"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge)}
                src={healthHackMicrosoft2019}
                alt="Health Hack Microsoft 2019 Award"
              />
            </div>
          </Slider>
        </div>
      </div>
    )
  }
}
const cssstyle = `
.slick-slide, .slick-slide *{ outline: none !important; };
`

Awards.displayName = 'Awards'

export default withStyles(styles)(Awards)
