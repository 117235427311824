import {Grid, Typography} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import settings from '../../../config/settings'
import email from '../../assets/email.svg'
import github from '../../assets/github.svg'
import instagram from '../../assets/instagram.svg'
import linkedin from '../../assets/linkedin.svg'
import location from '../../assets/location.svg'
import phonecall from '../../assets/phonecall.svg'
import twitter from '../../assets/twitter.svg'
import BasePage from '../common/BasePage.jsx'
import ContactUsCTAForm from './ContactUsCTAForm.jsx'
import {getPage} from './redux/actions'

class ContactUs extends React.Component {
  componentWillMount() {
    this.props.getPage('contact-us')
  }

  render() {
    const {page, isFetching, notFound} = this.props

    return (
      <BasePage className="contactUs" page={page} isFetching={isFetching} notFound={notFound}>
        <Helmet>
          <link rel="canonical" href={`${settings.appBaseURL}/contact-us`} />
          <meta property="og:url" ref={`${settings.appBaseURL}/contact-us`} />
        </Helmet>
        {page && (
          <div>
            <Grid item md={12} lg={6}>
              <h2 className="contactUs__title">Let&rsquo;s make your idea happen!</h2>
            </Grid>
            <Grid container spacing={4} className="contactUs__page_content">
              <Grid item sm={12} md={6}>
                <Divider variant="fullwidth" className="contactUs__page_content__divider" />
                <div />
                <div className="contactUs__page_content__left">
                  <Grid container>
                    <Grid item sm={6} md={6} id="1">
                      <Grid item sm={12} md={6}>
                        <h2 className="contactUs__title_large_screen">
                          Let&rsquo;s make your idea happen!
                        </h2>
                      </Grid>
                      <a
                        href="https://www.google.com/maps/place/The+SilverLogic/@26.4015622,-80.1001943,15z/data=!4m5!3m4!1s0x0:0xe3b8582075db660c!8m2!3d26.4015622!4d-80.1001943"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Grid container className="contactUs_address">
                          <Grid item xs={2} className="contactus_address__icon">
                            <img src={location} alt="locationIcon" />
                          </Grid>
                          <Grid item xs={10} className="contactus_address__info">
                            6413 Congress Ave, Suite 130, Boca Raton, FL 33487
                          </Grid>
                        </Grid>
                      </a>
                    </Grid>
                    <a style={{color: 'black'}} href="tel:5615692366">
                      <Grid item xs={12}>
                        <Grid container className="contactus_address">
                          <Grid item xs={2} className="contactus_address__icon">
                            <img src={phonecall} alt="phoncallIcon" />
                          </Grid>
                          <Grid item xs={10} className="contactus_address__info">
                            (561) 569-2366
                          </Grid>
                        </Grid>
                      </Grid>
                    </a>

                    <Grid item xs={12}>
                      <a style={{color: 'black', textAlign: 'left'}} href="mailto:info@tsl.io">
                        <Grid container className="contactus_address">
                          <Grid item xs={2} className="contactus_address__icon">
                            <img src={email} alt="emailIcon" />
                          </Grid>
                          <Grid item xs={10} className="contactus_address__info">
                            info@tsl.io
                          </Grid>
                        </Grid>
                      </a>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      padding: '13px 0 0 10px',
                      position: 'absolute',
                      maxWidth: '350px',
                    }}
                  >
                    <Grid container alignItems="center">
                      <Grid
                        item
                        sx={2}
                        style={{
                          border: '1px solid #AFBCC4',
                          borderRadius: '30px',
                          padding: '6px 24px 6px 24px',
                          color: '#25262E',
                        }}
                      >
                        Newsletter
                      </Grid>
                      <Grid item sx={1} style={{width: '30px', padding: '2px', marginLeft: '10px'}}>
                        <a
                          href="https://github.com/silverlogic"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={github} alt="githubIcon" />
                        </a>
                      </Grid>
                      <Grid item sx={1} style={{width: '30px', padding: '5px', marginLeft: '10px'}}>
                        <a
                          href="https://www.linkedin.com/company/silverlogic-llc"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={linkedin} alt="linkedinIcon" />
                        </a>
                      </Grid>
                      <Grid item sx={1} style={{width: '30px', padding: '5px', marginLeft: '10px'}}>
                        <a
                          href="https://twitter.com/thesilverlogic"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={twitter} alt="twitterIcon" />
                        </a>
                      </Grid>
                      <Grid item sx={1} style={{width: '30px', padding: '3px', marginLeft: '10px'}}>
                        <a
                          href="https://www.instagram.com/thesilverlogic"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={instagram} alt="instagramIcon" />
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                {page.ctaform && !isFetching && <ContactUsCTAForm ctaform={page.ctaform} />}
              </Grid>
            </Grid>
          </div>
        )}
      </BasePage>
    )
  }
}

ContactUs.displayName = 'ContactUs'

function mapStateToProps(state) {
  return {...state.pages}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getPage}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)
