import {call, put} from 'redux-saga/effects'
import {takeEvery} from 'redux-saga'
import {replace} from 'react-router-redux'
import SkillsApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* skillsFlow() {
  yield [takeEvery(Types.SKILLS_LIST_GET, getSkillsList)]
  yield [takeEvery(Types.SKILL_GET, getSkill)]
}

function* getSkill(action) {
  const {slug} = action

  try {
    const result = yield call(SkillsApi.getSkill, slug)
    yield put(Actions.getSkillSuccess(result))
  } catch (error) {
    yield put(Actions.getSkillFailure(error))
    yield put(replace('/services'))
  }
}

function* getSkillsList(action) {
  try {
    const {params} = action
    const result = yield call(SkillsApi.getList, params)
    yield put(Actions.getSkillsListSuccess(result.results))
  } catch (error) {
    yield put(Actions.getSkillsListFailure(error))
  }
}
