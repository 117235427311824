import classNames from 'classnames'
import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {Link} from 'react-router'
import {bindActionCreators} from 'redux'

import BasePage from '../common/BasePage.jsx'
import LazyImage from '../common/LazyImage'
import {retinaOrNormalSrc} from '../utils/ImageUtils.js'
import {getInnovation} from './redux/actions'

class InnovationDetails extends React.Component {
  componentWillMount() {
    this.props.getInnovation(this.props.params.slug, {expand: 'components,sections'})
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params.slug !== nextProps.params.slug) {
      this.props.getInnovation(nextProps.params.slug, {expand: 'components,sections'})
    }
  }

  render() {
    const {innovation, isFetching, notFound} = this.props
    const triangleSizes = ['largest', 'medium', 'small', 'medium', 'large', 'small']

    let customHeader = null
    if (innovation) {
      customHeader = (
        <div
          className="innovationDetails__header"
          style={{backgroundColor: innovation.primaryColor}}
        >
          <div className="row container">
            <div className="small-6 columns">
              <h1>{innovation.title}</h1>
              <span className="show-for-medium-up">{innovation.tagline}</span>
            </div>
            <div className="small-6 columns mockup">
              <img
                src={retinaOrNormalSrc(innovation.mockup, 'detailsRetinaSize', 'detailsNormalSize')}
                alt={`${innovation.title} mockup`}
              />
            </div>
          </div>
        </div>
      )
    }

    return (
      <BasePage
        className="innovationDetails"
        page={innovation}
        isFetching={isFetching}
        notFound={notFound}
        customHeader={customHeader}
      >
        {innovation && (
          <div>
            <Helmet>
              <link rel="canonical" href={`https://tsl.io/innovations/${innovation.slug}`} />
              <meta property="og:url" content={`https://tsl.io/innovations/${innovation.slug}`} />
            </Helmet>
            <style type="text/css">
              {`.innovationDetails__section__image::before {
              background-color: ${innovation.primaryColor};
            }`}
            </style>

            <div className="row container">
              <div className="large-6 columns innovationDetails__components">
                <h2>Components Used</h2>
                <ul>
                  {innovation.components.map((component) => {
                    return <li key={component.id}>{component.title}</li>
                  })}
                </ul>
              </div>
              <div className="large-6 columns innovationDetails__whatWeDid">
                <h2>What We Did</h2>
                <p dangerouslySetInnerHTML={{__html: innovation.whatWeDid}} />
              </div>
            </div>

            <div className="innovationDetails__howWeDid">
              <div className="row container">
                <div className="medium-12 columns">
                  <h2>How We Did It</h2>
                  <p dangerouslySetInnerHTML={{__html: innovation.howWeDid}} />
                </div>
              </div>
            </div>

            {innovation.sections.map((section, i) => {
              const classSize = triangleSizes[i % triangleSizes.length]
              const isRight = i % 2
              const imageClassName = classNames(
                'large-6 columns innovationDetails__section__image',
                {
                  'innovationDetails__section__image--right': isRight,
                  ['innovationDetails__section__image--' + classSize]: true,
                  ['innovationDetails__section__image--' + classSize + '--right']: isRight,
                },
              )
              return (
                <div className="innovationDetails__section" key={section.id}>
                  <div className="row container">
                    <div className={imageClassName}>
                      <LazyImage
                        src={retinaOrNormalSrc(section.image)}
                        alt={`${section.title} mockup`}
                      />
                    </div>
                    <div className="large-6 columns innovationDetails__section__content">
                      <div className="innovationDetails__section__content__marginTop">
                        <h2>{section.title}</h2>
                        <p dangerouslySetInnerHTML={{__html: section.body}} />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}

            <div className="innovationDetails__navigation">
              <div className="row container">
                <div className="small-6 columns end innovationDetails__navigation__prev">
                  {innovation.previous && (
                    <Link
                      to={`/innovations/${innovation.previous.slug}`}
                      title={innovation.previous.title}
                    >
                      <svg style={{width: 30, height: 30}}>
                        <use xlinkHref="#fa-sprite--angle-left" />
                      </svg>{' '}
                      <span className="show-for-medium-up">PREVIOUS INNOVATION</span>
                    </Link>
                  )}
                </div>
                <div className="small-6 columns innovationDetails__navigation__next">
                  {innovation.next && (
                    <Link to={`/innovations/${innovation.next.slug}`} title={innovation.next.title}>
                      <span className="show-for-medium-up">NEXT INNOVATION</span>{' '}
                      <svg style={{width: 30, height: 30, top: 10}}>
                        <use xlinkHref="#fa-sprite--angle-right" />
                      </svg>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </BasePage>
    )
  }
}

InnovationDetails.displayName = 'InnovationDetails'

function mapStateToProps(state) {
  return {
    ...state.innovations,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getInnovation}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InnovationDetails)
