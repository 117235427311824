import {Grid, Hidden, Tooltip, Typography} from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import {withStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {Carousel} from 'react-responsive-carousel'
import {Link} from 'react-router'
import {bindActionCreators, compose} from 'redux'

import settings from '../../../config/settings'
import background from '../../assets/Background.png'
import LampIcon from '../../assets/home-page/lamp.png'
import Loading from '../common/Loading.jsx'
import NotFound from '../common/NotFound.Page.jsx'
import HomeApi from '../home/api'
import {fadeAnimationHandler} from '../home/Home'
import * as Actions from './redux/actions'

const styles = (theme) => ({
  discoverGrid: {
    width: '100%',
    maxWidth: '100%',
    margin: 'auto',
    marginTop: '40px',
    backgroundImage: `linear-gradient(180deg, rgba(245, 249, 252, 0) 0%, #f2f2f2 100%), url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      background: '#ffffff',
      marginTop: '10px',
    },
  },

  discoverCalendar: {
    minHeight: 'calc(90vh - 75px)',
  },

  discoverEmbedded: {
    background: '#ffffff',
    border: '1px solid #e1e9ee',
    marginTop: 80,
    margin: '0 auto',
    boxSizing: 'border-box',
    boxShadow:
      '0px 24px 38px rgba(0, 163, 255, 0.14), 0px 9px 46px rgba(96, 97, 112, 0.12), 0px 11px 15px rgba(40, 41, 61, 0.2)',
    borderRadius: '4px',
    minHeight: '660px',
    height: '660px',
    padding: '32px',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  background: {
    backgroundImage: `linear-gradient(180deg, rgba(245, 249, 252, 0) 0%, #F2F2F2 100%), url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    minHeight: 'calc(90vh - 75px)',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      justifyContent: 'space-evenly',
      height: 'auto',
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '60% 40%',
    width: '100%',
    maxWidth: '1150px',
    gridTemplateAreas: '"text carousel"',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '50% 50%',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      gridTemplateAreas: '"text"',
      marginTop: '180px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '140px',
    },
  },
  left: {
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    gridArea: 'text',
    height: '100%',
    alignItems: 'flex-start',
  },
  right: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gridArea: 'carousel',
    zIndex: 2,
  },
  text: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '70px',
    lineHeight: '100%',
    color: '#25262E',
    letterSpacing: '-1px',
    marginLeft: '64px',
    [theme.breakpoints.down('sm')]: {
      margin: '0px 96px',
      fontSize: '48px',
      lineHeight: '110%',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0px 16px',
      fontSize: '36px',
      lineHeight: '110%',
    },
  },
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  discoverCarousel: {
    minWidth: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '4px 0px 0px 0px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  discoverCTA: {
    minWidth: '100%',
    minHeight: 500,
    backgroundColor: '#1c1b22',
    borderRadius: '0px',
    padding: '24px 63px',

    '&:hover $arrowLine': {
      width: '95%',
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '96%',
      },
    },
    '&:hover $leftIcon': {
      backgroundColor: '#29B2FF',
      border: 'none',
    },
    '&:hover $discoverPercentsTextLeft': {
      fontSize: '29px',
      color: '#29B2FF',
    },
    '&:hover $discoverPercentsText': {
      fontSize: '16.5px',
      lineHeight: '20px',
    },
    '&:hover $discoverPercentsTextPercentLeft': {
      fontSize: '70px',
      color: '#29B2FF',
    },
    '&:hover $discoverPercentsTextPercent': {
      fontSize: '39px',
      lineHeight: '40px',
      marginTop: 25,
    },
    '&:hover $discoverIconTextColor': {
      color: '#29B2FF',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      minHeight: 375,
      padding: '20px',
    },
  },
  page: {
    overflow: 'auto',
  },
  discoverRight: {
    minHeight: 'calc(90vh - 75px)',

    marginTop: 80,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  leftIcon: {
    marginRight: '8px',
    backgroundColor: '#1c1b22',
    border: 'solid',
    borderRadius: '40px',
    height: `30px`,
    width: `30px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px',
    top: '-5px',
    transition: 'backgroundColor 800ms, border 800ms',
    '&:hover': {
      backgroundColor: '#29B2FF',
      border: 'none',
    },
  },
  discoverText: {
    fontFamily: 'General Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '21px',
    color: '#E1E9EE',
    marginTop: 24,
    width: 363,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  discoverImg: {
    minWidth: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      minHeight: 300,
    },
  },
  discoverPercents: {
    width: 330,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  discoverPercentsText: {
    fontFamily: 'General Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '29px',
    color: '#E1E9EE',
    transition: 'fontSize 800ms, lineHeight 800ms',
    [theme.breakpoints.down('xs')]: {
      fontSize: '30px',
    },
  },
  discoverPercentsTextLeft: {
    fontFamily: 'General Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '29px',
    color: '#E1E9EE',
    transition: 'fontSize 800ms, color 800ms',
    [theme.breakpoints.down('xs')]: {
      fontSize: '30px',
    },
  },
  discoverPercentsTextPercent: {
    fontFamily: 'General Sans',
    fontStyle: 'normal',
    fontWeight: '200',
    fontSize: '52px',
    lineHeight: '68px',
    color: '#E1E9EE',
    letterSpacing: '-2px',
    transition: 'fontSize 800ms, lineHeight 800ms',
    [theme.breakpoints.down('xs')]: {
      fontSize: '60px',
      marginTop: 48,
    },
  },
  discoverPercentsTextPercentLeft: {
    fontFamily: 'General Sans',
    fontStyle: 'normal',
    fontWeight: '200',
    fontSize: '52px',
    lineHeight: '68px',
    color: '#E1E9EE',
    letterSpacing: '-2px',
    transition: 'fontSize 800ms, color 800ms',
    '&:hover': {
      fontSize: '70px',
      color: '#29B2FF',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '60px',
      marginTop: 32,
    },
  },
  discoverIconText: {
    fontFamily: 'General Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '21px',
    display: 'inline',
    color: '#FFFFFF',
  },
  discoverIconTextColor: {
    fontFamily: 'General Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '21px',
    display: 'inline',
    color: '#FFFFFF',
    transition: 'color 800ms',
    [theme.breakpoints.down('xs')]: {
      color: '#29B2FF',
    },
  },
  discoverIcon: {
    marginTop: 132,
    [theme.breakpoints.down('xs')]: {
      marginTop: 64,
    },
  },
  arrowLine: {
    borderTop: '1px solid #FFFFFF',
    width: '70%',
    display: 'inline-block',
    transition: 'width 800ms',
    [theme.breakpoints.down('xs')]: {
      width: '96%',
    },
  },
  arrow: {
    border: 'solid #FFFFFF',
    borderWidth: '0 1px 1px 0',
    display: 'inline-block',
    padding: 5,
    top: '4.57px',
    right: '12px',
    transform: 'rotate(-45deg)',
    webkitTransform: 'rotate(-45deg)',
  },
  discoverIconInline: {
    display: 'inline-box',
  },
})

class DiscoverYourIdea extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scriptLoaded: false,
      projects: [],
    }
  }

  componentWillMount() {
    this.props.getPage('discover-your-idea')
    HomeApi.getProjectsCarouselDiscover().then(({results}) => {
      this.setState((prevState) => ({...prevState, projects: results}))
    })
  }

  componentDidMount() {
    this.addHbsptScript()
  }

  componentDidUpdate() {
    this.addHbsptScript()
  }

  addHbsptScript() {
    const {page} = this.props
    if (!this.state.scriptLoaded && page && page.slug === 'discover-your-idea') {
      const script = document.createElement('script')
      script.src = page.sections.script.body
      let elem
      const interval = setInterval(() => {
        elem = document.getElementById('embended-calendar')
        if (elem) {
          elem.appendChild(script)
          this.setState({scriptLoaded: true})
          clearInterval(interval)
        }
      }, 500)
    }
  }

  render() {
    const {page, isFetching, classes} = this.props
    const {projects} = this.state

    if (isFetching) return <Loading />
    if (!page) return <NotFound />
    return (
      <div>
        <Helmet title={page.title}>
          <link rel="canonical" href={`${settings.appBaseURL}/discover-your-idea`} />
          <meta property="og:url" content={`${settings.apiBaseURL}/discover-your-idea`} />
        </Helmet>
        <h1>{page.title}</h1>
        <Grid container className={classes.discoverGrid}>
          <Grid item md={7} xs={12} className={classes.discoverCalendar} id="embended-calendar">
            <div
              className={classes.discoverEmbedded}
              dangerouslySetInnerHTML={{__html: page.body}}
            />
          </Grid>

          <Grid item md={5} xs={12} className={classes.discoverRight}>
            <div className={classes.discoverCarousel}>
              {!!projects && !!projects.length && (
                <div className={classes.right}>
                  <Carousel
                    showArrows={false}
                    dynamicHeight
                    infiniteLoop
                    autoPlay
                    stopOnHover
                    showThumbs={false}
                    showIndicators={false}
                    animationHandler={fadeAnimationHandler}
                    swipeable={false}
                    showStatus={false}
                  >
                    {projects.map((project) => {
                      return project.discoverPageImages.map(({id, image}) => {
                        if (image) {
                          return (
                            <Tooltip title={`Learn more about ${project.title}`} placement="top">
                              <div key={`${project.id} - ${id}`} className={classes.imageWrapper}>
                                <img alt="" className={classes.discoverImg} src={image?.fullSize} />
                              </div>
                            </Tooltip>
                          )
                        }

                        return null
                      })
                    })}
                  </Carousel>
                </div>
              )}
            </div>
            <div className={classes.discoverCTA}>
              <Typography className={classes.discoverText}>
                When you engage The SilverLogic as your development partner it's
              </Typography>
              <Grid container className={classes.discoverPercents}>
                <Grid item sm={5} xs={12} style={{textAlign: 'center'}}>
                  <Typography className={classes.discoverPercentsTextPercentLeft}>100%</Typography>
                  <Typography className={classes.discoverPercentsTextLeft}>Your Idea</Typography>
                </Grid>
                <Grid item sm={2} xs={0} />
                <Grid item sm={5} xs={12} style={{textAlign: 'center'}}>
                  <Typography className={classes.discoverPercentsTextPercent}>100%</Typography>
                  <Typography className={classes.discoverPercentsText}>Your Code</Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.discoverIcon}>
                <div className={classes.discoverIconInline}>
                  <Icon className={classes.leftIcon}>
                    <img src={LampIcon} alt="lamp" />
                  </Icon>
                  <Typography className={classes.discoverIconText}>
                    Discover your idea with our
                  </Typography>
                  <Link href="#embended-calendar">
                    <Typography className={classes.discoverIconTextColor}> Concept Map</Typography>
                  </Link>
                </div>
                <Grid item xs={12}>
                  <div className={classes.arrowLine} />
                  <i className={classes.arrow} />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {...state.pages}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

DiscoverYourIdea.displayName = 'Discover Your Idea'
DiscoverYourIdea.contextTypes = {
  router: PropTypes.object.isRequired,
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(DiscoverYourIdea)
