import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

import React from 'react'

export class LazyImage extends React.Component {
  // Just render the image with data-src
  render() {
    const {alt, src, className} = this.props
    return <img alt={alt} className={`lazyload ${className}`} data-src={src} />
  }
}

export default LazyImage
