import {takeEvery} from 'redux-saga'
import {call, put} from 'redux-saga/effects'

import Api from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* feedFlow() {
  yield [takeEvery(Types.SOCIAL_FEED_GET, getSocialFeed)]
  yield [takeEvery(Types.BLOG_POSTS_GET_BY_TOPIC, getBlogPostsByTopic)]
}

function* getSocialFeed() {
  try {
    const result = yield call(Api.getSocialFeed)
    yield put(Actions.getSocialFeedSuccess(result.results))
  } catch (error) {
    yield put(Actions.getSocialFeedFailure(error))
  }
}

function* getBlogPostsByTopic(action) {
  const {topicId} = action
  try {
    const result = yield call(Api.getRecentBlogPosts, {topic_id: topicId})
    yield put(Actions.getRecentBlogPostsSuccess(result.results))
  } catch (error) {
    yield put(Actions.getRecentBlogPostsFailure(error))
  }
}
