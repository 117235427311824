import * as Types from './actions.types'

function createInitialState() {
  return {
    list: [],
    skill: null,
    isFetching: false,
    notFound: false,
  }
}

export default function skills(state = createInitialState(), action) {
  switch (action.type) {
    case Types.SKILLS_LIST_GET:
      return {
        ...createInitialState(),
        list: [],
        isFetching: true,
      }
    case Types.SKILLS_LIST_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        notFound: false,
        list: action.skills,
      }
    case Types.SKILLS_LIST_GET_FAILURE:
      return {
        ...state,
        list: [],
        isFetching: false,
        notFound: true,
      }

    case Types.SKILL_GET:
      return {
        ...createInitialState(),
        skill: null,
        isFetching: true,
      }
    case Types.SKILL_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        notFound: false,
        skill: action.skill,
      }
    case Types.SKILL_GET_FAILURE:
      return {
        ...state,
        skill: null,
        isFetching: false,
        notFound: true,
      }

    default:
      return state
  }
}
