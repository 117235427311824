import * as Types from './actions.types'

export function getTechsList() {
  return {type: Types.TECHS_LIST_GET}
}

export function getTechsListSuccess(techs) {
  return {type: Types.TECHS_LIST_GET_SUCCESS, techs}
}

export function getTechsListFailure(error) {
  return {type: Types.TECHS_LIST_GET_FAILURE, error}
}
