import {takeEvery} from 'redux-saga'
import {call, put} from 'redux-saga/effects'

import TagsApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* positionsFlow() {
  yield [takeEvery(Types.TAGS_LIST_GET, getTagsList)]
}

function* getTagsList(action) {
  try {
    const {params} = action
    const result = yield call(TagsApi.getList, params)
    yield put(Actions.getTagsListSuccess(result.results))
  } catch (error) {
    yield put(Actions.getTagsListFailure(error))
  }
}
