import {Hidden} from '@material-ui/core'
import classNames from 'classnames'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router'

import settings from '../../../config/settings'
import closeIcon from '../../assets/close.svg'
import imgLogo from '../../assets/logo.png'
import phoneIcon from '../../assets/phone.svg'

class Submenu1 extends React.Component {
  render() {
    return (
      <ul className="nav__submenu">
        <li className="nav__submenu-item">
          <Link to="/culture">Culture</Link>
        </li>
        <li className="nav__submenu-item">
          <Link to="/careers">Careers</Link>
        </li>
        <li className="nav__submenu-item">
          <a href="https://blog.tsl.io/">Blog</a>
        </li>
      </ul>
    )
  }
}
// class Submenu2 extends React.Component {
//   render() {
//     return (
//       <ul className="nav__submenu">
//         <li className="nav__submenu-item">
//           <Link to="/projects">Projects</Link>
//         </li>
//         <li className="nav__submenu-item">
//           <Link to="/innovations">Innovations</Link>
//         </li>
//       </ul>
//     )
//   }
// }

export class Header extends React.Component {
  state = {
    menuOpened: false,
    showAboutMenu: false,
  }

  toggleMenu = (e) => {
    e.preventDefault()
    this.setState({menuOpened: !this.state.menuOpened})
  }

  hideMenu = (e) => {
    if (e.target.nodeName === 'A') {
      this.setState({menuOpened: false})
    }
  }

  handleHover = () => {
    this.setState({showAboutMenu: true})
  }

  handleLeave = () => {
    this.setState({showAboutMenu: false})
  }

  render() {
    return (
      <header
        className={classNames('ba-site-header', {
          'ba-site-header--opened': this.state.menuOpened,
        })}
      >
        {' '}
        <div className="clearfix ba-site-header__container" style={{display: 'flex'}}>
          <div className="ba-site-header__container__left">
            <Link
              to="/"
              onClick={(e) => {
                this.hideMenu(e)
                this.props.onHomeClick()
              }}
              title={get(settings, 'website.title')}
              className="ba-site-header-home"
            >
              <img
                className="ba-site-header-logo"
                src={imgLogo}
                alt={get(settings, 'website.title')}
              />
            </Link>
          </div>
          <div className="ba-site-header__container__right">
            <Hidden xsDown>
              <nav className="nav">
                <ul className="nav__menu">
                  <li className="nav__menu-item" onMouseLeave={this.handleLeave}>
                    <Link onMouseEnter={this.handleHover} to="/about-us">
                      About Us
                      <svg className="nav__menu-item__icon">
                        <use xlinkHref="#fa-sprite--angle-down" />
                      </svg>
                    </Link>
                    {this.state.showAboutMenu && <Submenu1 />}
                  </li>
                  <li className="nav__menu-item">
                    <Link to="/services">Services</Link>
                  </li>
                  <li className="nav__menu-item" onMouseLeave={this.handleLeave}>
                    <Link onMouseEnter={this.handleHover} to="/portfolio">
                      Portfolio
                      {/* <svg className="nav__menu-item__icon">
                      <use xlinkHref="#fa-sprite--angle-down" />
                    </svg> */}
                    </Link>
                    {/* {this.state.showAboutMenu && <Submenu2 />} */}
                  </li>
                  <li className="nav__menu-item">
                    <Link to="/contact-us">Contact&nbsp;Us</Link>
                  </li>
                </ul>
              </nav>
            </Hidden>
            <Hidden smUp>
              <a className="ba-site-header__toggle" href="tel:5615692366">
                <img src={phoneIcon} style={{marginRight: '8px'}} />
              </a>
              <button className="ba-site-header__toggle" onClick={this.toggleMenu}>
                {this.state.menuOpened ? (
                  <img src={closeIcon} />
                ) : (
                  <span>
                    <span className="ba-site-header__toggle__line" />
                    <span className="ba-site-header__toggle__line" />
                    <span className="ba-site-header__toggle__line" />
                  </span>
                )}
              </button>
            </Hidden>
          </div>
        </div>
        {this.state.menuOpened && (
          <div className="ba-site-header__menuContainer" onClick={this.hideMenu}>
            <div className="row container">
              <div className="small-12 medium-2 large-2 columns"> </div>
              <div className="small-12 medium-2 large-2 columns">
                <h2>
                  <Link to="/about-us">About Us</Link>
                </h2>
                <ul>
                  <li>
                    <Link to="/culture">Culture</Link>
                  </li>
                  <li>
                    <Link to="/careers">Careers</Link>
                  </li>
                  <li>
                    <a href="https://blog.tsl.io/">Blog</a>
                  </li>
                </ul>
              </div>
              <div className="small-12 medium-2 large-2 columns">
                <h2>
                  <Link to="/services">Services</Link>
                </h2>
                <ul>
                  <li>
                    <Link to="/case-studies">Case Studies</Link>
                  </li>
                </ul>
              </div>

              <div className="small-12 medium-2 large-2 columns">
                <h2>
                  <Link to="/portfolio">Portfolio</Link>
                </h2>
                {/* <ul>
                  <li>
                    <Link to="/projects">Projects</Link>
                  </li>
                  <li>
                    <Link to="/innovations">Innovations</Link>
                  </li>
                </ul> */}
              </div>
              <div className="small-12 medium-2 large-2 columns end">
                <h2>
                  <Link to="/contact-us">Contact&nbsp;Us</Link>
                </h2>
              </div>
            </div>
          </div>
        )}
      </header>
    )
  }
}

Header.displayName = 'Header'
Header.propTypes = {
  onHomeClick: PropTypes.func.isRequired,
}
Header.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps)(Header)
