import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import get from 'lodash/get'
import _keys from 'lodash/keys'
import union from 'lodash/union'
import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import BasePage from '../common/BasePage.jsx'
import {getPage} from '../pages/redux/actions'
import {getTagsList} from '../tags/redux/actions.js'
import {arrayAdd, arrayRemoveAt} from '../utils/ReduxUtils.js'
import CareersCTAForm from './CareersCTAForm.jsx'
import {getPositionsList} from './redux/actions.js'

dayjs.extend(relativeTime)

class Careers extends React.Component {
  state = {searchKeyword: '', searchLocation: '', searchTags: []}

  componentWillMount() {
    this.props.getPage('careers')
    this.props.getPositionsList()
    this.props.getTagsList()
  }

  toggleTag = (e) => {
    const {value} = e.target
    const pos = this.state.searchTags.indexOf(value)
    if (pos > -1) {
      this.setState({searchTags: arrayRemoveAt(this.state.searchTags, pos)}, () => {
        this.handleSubmitSearch(e)
      })
    } else {
      this.setState({searchTags: arrayAdd(this.state.searchTags, value)}, () => {
        this.handleSubmitSearch(e)
      })
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  handleSubmitSearch = (e) => {
    e.preventDefault()
    this.props.getPositionsList({
      location: this.state.searchLocation,
      title: this.state.searchKeyword,
      keywords_id: this.state.searchTags.join(','),
    })
  }

  render() {
    const {
      page,
      isFetching,
      notFound, //, openings, tags
    } = this.props
    const positionsLeft = []
    const positionsRight = []

    if (!isFetching && page) {
      const keysFound = _keys(page.sections)
      const keys = union(
        ['softwareEngineer', 'fullStackQaQngineer', 'productOwner', 'scrumMaster'],
        keysFound,
      )

      keys.map((key, index) => {
        const position = get(page.sections, key)
        if (position) {
          const positionDiv = (
            <div key={key} className="careersPage__positions__list__item">
              <h3>{position.title}</h3>
              <p dangerouslySetInnerHTML={{__html: position.body}} />
            </div>
          )

          if (index % 2) {
            positionsRight.push(positionDiv)
          } else {
            positionsLeft.push(positionDiv)
          }
        }
        return key
      })
    }

    // const isPositionsFetching = openings.isFetching || tags.isFetching

    return (
      <BasePage className="careersPage" page={page} isFetching={isFetching} notFound={notFound}>
        <Helmet>
          <link rel="canonical" href="https://tsl.io/careers" />
          <meta property="og:url" href="https://tsl.io/careers" />
        </Helmet>
        {page && (
          <div>
            <div className="careersPage__interested">
              <div className="row container">
                <h2>
                  Interested in working with us?
                  <a href="https://silverlogic.bamboohr.com/jobs/" target="_blank" rel="noreferrer">
                    {' '}
                    Check out our current job postings here.
                  </a>
                </h2>
                <div className="careersPage__interested__content" />
              </div>
            </div>
            {/* <div className="careersPage__openings">
              <div className="row container">
                <div className="careersPage__openings__search">
                  <form className="clearfix" onSubmit={this.handleSubmitSearch}>
                  <div className="small-12 medium-6 columns careersPage__openings__search__keyword">
                    <div className="row">
                      <div className="small-8 large-9 columns">
                        <input type="text" name="searchKeyword" placeholder="Search by Keyword" onChange={this.handleChange} value={this.state.searchKeyword} />
                      </div>
                      <div className="small-4 large-3 columns buttonColumn">
                        <button type="submit">Search</button>
                      </div>
                    </div>
                  </div>
                  <div className="small-12 medium-6 columns careersPage__openings__search__location">
                    <div className="row">
                      <div className="small-8 large-9 columns">
                        <input type="text" name="searchLocation" placeholder="Search by Location" onChange={this.handleChange} value={this.state.searchLocation} />
                      </div>
                      <div className="small-4 large-3 columns buttonColumn">
                        <button type="submit">Search</button>
                      </div>
                    </div>
                  </div>
                  <div className="medium-12 columns careersPage__openings__search__tags">
                    {tags.list.map((tag) => {
                      return (<label key={tag.id}>
                        <input type="checkbox" value={tag.id} onChange={this.toggleTag} /> {tag.name}
                      </label>);
                    })}
                  </div>
                </form>
                  <div className="careersPage__openings__search__results">
                    {isPositionsFetching && <Loading />}
                    {openings.list.map((position) => {
                      return (
                        <Link
                          key={position.id}
                          className="careersPage__openings__search__results__item"
                          to={`/careers/${position.slug}`}
                        >
                          <span className="careersPage__openings__search__results__item__title">
                            {position.title}
                          </span>
                          <span className="careersPage__openings__search__results__item__address">
                            {position.address}
                          </span>
                          <span className="careersPage__openings__search__results__item__when">
                            Posted
                            {' '}
                            {dayjs(position.created).fromNow()}
                          </span>
                          <span className="careersPage__openings__search__results__item__keyword">
                            {position.keywords.map((keyword, i) => {
                              return <span key={i}>{keyword}</span>
                            })}
                          </span>
                        </Link>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>*/}

            <div className="careersPage__positions">
              <div className="row container">
                <h2>What we look for</h2>
                <div className="medium-6 columns careersPage__positions__list careersPage__positions__list--left">
                  {positionsLeft}
                </div>
                <div className="medium-6 columns careersPage__positions__list careersPage__positions__list--right">
                  {positionsRight}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="skillDetails__CTA_bottom_padding">
          {page && page.ctaform && <CareersCTAForm class="row" ctaform={page.ctaform} />}{' '}
        </div>
      </BasePage>
    )
  }
}

Careers.displayName = 'Careers'

function mapStateToProps(state) {
  // return {...state.pages, openings: state.positions, tags: state.tags}
  return {...state.pages}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getPage, getPositionsList, getTagsList}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Careers)
