import settings from '../../../config/settings'
import axios from '../api/axios'

export default class ProjectsApi {
  static getProject(slug, params) {
    return axios.get(`${settings.apiBaseURL}/projects/${slug}`, {params})
  }

  static getList(params) {
    return axios.get(`${settings.apiBaseURL}/projects`, {params})
  }
}
