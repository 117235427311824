import './styles/main.scss'

import {loadableReady} from '@loadable/component'
import createRavenMiddleware from 'raven-for-redux'
import Raven from 'raven-js'
import React from 'react'
import ReactDOM from 'react-dom'
import {browserHistory} from 'react-router'
import {routerMiddleware, syncHistoryWithStore} from 'react-router-redux'
import {applyMiddleware, createStore} from 'redux'
import reduxCatch from 'redux-catch'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'

import settings from '../config/settings'
import Root from './Root.jsx'
import reducers from './shared/reducers'
import sagas from './shared/sagas'

// Export React so the dev tools can find it
if (window === window.top) {
  window.React = React
}

const sagaMiddleware = createSagaMiddleware()

let initialState = null
if (window.__STATE__) {
  initialState = window.__STATE__
}

// create a store with middlewares
const reduxMiddleware = [thunkMiddleware, sagaMiddleware, routerMiddleware(browserHistory)]

if (settings.sentry && settings.sentry.publicDSN) {
  const {sentry} = settings
  Raven.config(sentry.publicDSN, {
    release: sentry.release,
    environment: sentry.environment,
  }).install()
  reduxMiddleware.unshift(createRavenMiddleware(Raven))
  reduxMiddleware.push(reduxCatch((error) => Raven.captureException(error)))
}
const store = createStore(reducers, initialState, applyMiddleware(...reduxMiddleware))

const history = syncHistoryWithStore(browserHistory, store)
sagaMiddleware.run(sagas)

// Google Analytics Pageview Tracking
if (settings.tracking.ga.isEnabled) {
  const initGA = (history) => {
    /* eslint-disable */
    ;(function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r
      ;(i[r] =
        i[r] ||
        function () {
          ;(i[r].q = i[r].q || []).push(arguments)
        }),
        (i[r].l = 1 * new Date())
      ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
      a.async = 1
      a.src = g
      m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')
    /* eslint-enable */

    window.ga('create', settings.tracking.ga.trackingId, 'auto')
    window.ga('send', 'pageview')

    history.listen((location) => {
      window.ga('send', 'pageview', location.pathname + location.search)
    })
  }
  initGA(history)
}

// Facebook Pixel Pageview Tracking
if (settings.tracking.fbPixel.isEnabled) {
  /* eslint-disable */
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
  /* eslint-enable */

  window.fbq('init', settings.tracking.fbPixel.trackingId)
  window.fbq('track', 'PageView')
}

// HubSpot Pageview Tracking
if (settings.tracking.hubspot.isEnabled) {
  const initHubSpot = (history) => {
    /* eslint-disable */
    const _hsq = (window._hsq = window._hsq || [])
    _hsq.push(['setPath', location.pathname + location.search])
    ;(function (b, e, v, t, s) {
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(
      document,
      'script',
      'https://js.hs-scripts.com/' + settings.tracking.hubspot.trackingId + '.js',
    )
    /* eslint-enable */

    history.listen((location) => {
      _hsq.push(['setPath', location.pathname + location.search])
      _hsq.push(['trackPageView'])
    })
  }
  initHubSpot(history)
}

// Adroll Tracking
/* eslint-disable */
;(function () {
  var _onload = function () {
    window.adroll_adv_id = '7NSPQO6WM5EOLGDXQUKXCF'
    window.adroll_pix_id = '5IA77RBMHFEYTA2S67RMLM'
    if (document.readyState && !/loaded|complete/.test(document.readyState)) {
      setTimeout(_onload, 10)
      return
    }
    if (!window.__adroll_loaded) {
      window.__adroll_loaded = true
      setTimeout(_onload, 50)
      return
    }
    const scr = document.createElement('script')
    const host =
      document.location.protocol == 'https:' ? 'https://s.adroll.com' : 'http://a.adroll.com'
    scr.setAttribute('async', 'true')
    scr.type = 'text/javascript'
    scr.src = host + '/j/roundtrip.js'
    ;(
      (document.getElementsByTagName('head') || [null])[0] ||
      document.getElementsByTagName('script')[0].parentNode
    ).appendChild(scr)
  }
  if (window.addEventListener) {
    window.addEventListener('load', _onload, false)
  } else {
    window.attachEvent('onload', _onload)
  }
})()
/* eslint-enable */

/* scroll to top on location change */
let prevLocation = {}
history.listen((location) => {
  const pathChanged = prevLocation.pathname !== location.pathname
  const hashChanged = prevLocation.hash !== location.hash
  const searchChanged = prevLocation.search !== location.search
  if (pathChanged || hashChanged || searchChanged) window.scrollTo(0, 0)
  prevLocation = location
})

const renderApp = () => {
  loadableReady(() => {
    ReactDOM.hydrate(<Root store={store} history={history} />, document.getElementById('app'))
  })
}

renderApp()

if (module.hot) {
  module.hot.accept('./Root.jsx', () => renderApp())
}
