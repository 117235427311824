import React from 'react'
import Provider from 'react-redux/es/components/Provider'
import {Router} from 'react-router'

import routing from './shared/routing'

export default function Root({store, history}) {
  return (
    <Provider store={store}>
      <Router history={history}>{routing(store)}</Router>
    </Provider>
  )
}
