import {takeEvery} from 'redux-saga'
import {call, put} from 'redux-saga/effects'

import CaseStudiesApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* caseStudiesFlow() {
  yield [takeEvery(Types.CASESTUDIES_LIST_GET, getCaseStudiesList)]
  yield [takeEvery(Types.CASESTUDY_GET, getCaseStudy)]
}

function* getCaseStudiesList(action) {
  try {
    const {params} = action
    const result = yield call(CaseStudiesApi.getList, params)
    yield put(Actions.getCaseStudiesListSuccess(result.results))
  } catch (error) {
    yield put(Actions.getCaseStudiesListFailure(error))
  }
}

function* getCaseStudy(action) {
  try {
    const {slug, params} = action
    const result = yield call(CaseStudiesApi.getCaseStudy, slug, params)
    yield put(Actions.getCaseStudySuccess(result))
  } catch (error) {
    yield put(Actions.getCaseStudyFailure(error))
  }
}
