import classNames from 'classnames'
import React from 'react'
import {Link} from 'react-router'

import LazyImage from '../common/LazyImage'
import Loading from '../common/Loading.jsx'

class SkillsList extends React.Component {
  state = {opened: false}

  toggle = (e) => {
    e.preventDefault()
    this.setState({opened: !this.state.opened})
  }

  render() {
    const {skills} = this.props
    return (
      <div className={classNames('skills tiltedBackground', {'skills--opened': this.state.opened})}>
        <div className="homeContainer">
          <h2>
            <a onClick={this.toggle} style={{color: '#28A2D1 !important'}}>
              What We Do
              <svg style={{width: 12, height: 8}} className="fa-caret-down only-mobile-visible">
                <use xlinkHref="#fa-sprite--caret-down" />
              </svg>
            </a>
          </h2>
          {skills.isFetching ? (
            <Loading />
          ) : (
            <div
              className={classNames('skills__list clearfix', {
                'skills__list--opened': this.state.opened,
              })}
            >
              {skills.list.map((skill) => {
                return (
                  <Link key={skill.id} to={`/services/${skill.slug}`}>
                    <div className="hexagonContainer">
                      <div className="hexagonContainer2">
                        <div className="hexagon">
                          <span className="hexagonSpan" />
                        </div>
                      </div>
                      <div className="imageTitle">
                        <div className="skillImage">
                          <LazyImage
                            src={skill.icon}
                            width={100}
                            height={100}
                            alt={`${skill.title}`}
                          />
                        </div>
                        <div className="skillTitle">
                          <p>{skill.title}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default SkillsList
