import {takeEvery} from 'redux-saga'
import {call, put} from 'redux-saga/effects'

import ProjectsApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* projectsFlow() {
  yield [takeEvery(Types.PROJECTS_LIST_GET, getProjectsList)]
  yield [takeEvery(Types.PROJECT_GET, getProject)]
}

function* getProjectsList(action) {
  try {
    const {params} = action
    const result = yield call(ProjectsApi.getList, params)
    yield put(Actions.getProjectsListSuccess(result.results))
  } catch (error) {
    yield put(Actions.getProjectsListFailure(error))
  }
}

function* getProject(action) {
  try {
    const {slug, params} = action
    const result = yield call(ProjectsApi.getProject, slug, params)
    yield put(Actions.getProjectSuccess(result))
  } catch (error) {
    yield put(Actions.getProjectFailure(error))
  }
}
