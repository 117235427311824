import React from 'react'
import Masonry from 'react-masonry-component'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import Loading from '../common/Loading.jsx'
import {getRecentBlogPostsByTopic} from '../feed/redux/actions'
import SocialFeedCell from '../feed/SocialFeedCell.jsx'

class RecentStories extends React.Component {
  componentWillMount() {
    this.props.getRecentBlogPostsByTopic(this.props.topicId)
  }

  render() {
    const {list, title, isFetching} = this.props

    if (!isFetching && list.length === 0) {
      return null
    }

    return (
      <div className="skillDetails__recentStories clearfix">
        <div className="row container">
          <h2>
            Recent Stories About&nbsp;
            {title}
          </h2>
        </div>

        {isFetching && <Loading />}

        <div className="social-container">
          <Masonry options={{gutter: 30}} className="social-grid">
            {list.map((post, i) => {
              return (
                <div key={i} className="social-grid-item">
                  <SocialFeedCell post={post} />
                </div>
              )
            })}
          </Masonry>
        </div>
      </div>
    )
  }
}

RecentStories.displayName = 'RecentStories'

function mapStateToProps(state) {
  return {...state.feed}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getRecentBlogPostsByTopic}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentStories)
