import React from 'react'

class BaseCTAForm extends React.Component {
  constructor(props) {
    super(props)
    const formNumber = Math.floor(Math.random() * 100)
    this.state = {
      formOpened: false,
      scriptLoaded: false,
      formElementId: `hubspotForm-${formNumber}`,
    }
  }

  createHbsptForm(hubspotFormId) {
    if (window.hbspt) {
      window.hbspt.forms.create({
        css: '',
        portalId: '3838537',
        formId: hubspotFormId,
        target: `#${this.state.formElementId}`,
      })
    }
  }

  openCTAForm = (e) => {
    if (e) {
      e.preventDefault()
    }

    const {hubspotFormId} = this.props.ctaform

    if (this.state.formOpened) {
      this.setState({formOpened: false})
    } else {
      this.setState({formOpened: true}, () => {
        if (this.state.scriptLoaded) {
          this.createHbsptForm(hubspotFormId)
        } else {
          const script = document.createElement('script')
          script.src = 'https://js.hsforms.net/forms/v2.js'
          document.body.appendChild(script)
          const ifIE = document.createElement('div')
          ifIE.innerHTML =
            '<!--[if lte IE 8]><script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script><![endif]-->'
          document.body.appendChild(ifIE)
          script.addEventListener('load', () => {
            this.createHbsptForm(hubspotFormId)
          })
          this.setState({scriptLoaded: true})
        }
      })
    }
  }
}

BaseCTAForm.displayName = 'BaseCTAForm'

export default BaseCTAForm
