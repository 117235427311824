import classNames from 'classnames'
import React, {Component} from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {Link} from 'react-router'
import {bindActionCreators} from 'redux'

import BasePage from '../common/BasePage.jsx'
import LazyImage from '../common/LazyImage'
import {getPage} from '../pages/redux/actions'
import {retinaOrNormalSrc} from '../utils/ImageUtils.js'
import CaseStudyCTAForm from './CaseStudyCTAForm.jsx'
import {getCaseStudiesList} from './redux/actions'

class CaseStudyItem extends Component {
  render() {
    const {caseStudy, isRight} = this.props

    return (
      <div className={classNames('row caseStudiesPage__list__item')}>
        <div
          className={classNames('medium-6 columns caseStudiesPage__list__item__image', {
            'caseStudiesPage__list__item__image--right': isRight,
          })}
        >
          <LazyImage src={retinaOrNormalSrc(caseStudy.listImage)} alt={`${caseStudy.title}`} />
        </div>
        <div
          className={classNames('medium-6 columns caseStudiesPage__list__item__content', {
            'caseStudiesPage__list__item__content--right': isRight,
          })}
        >
          <h2>
            <Link to={`/case-study/${caseStudy.slug}`}>{caseStudy.title}</Link>
          </h2>
          <p dangerouslySetInnerHTML={{__html: caseStudy.introduction}} />
          <Link
            to={`/case-study/${caseStudy.slug}`}
            className="caseStudiesPage__list__item__readMore"
          >
            Read More
          </Link>
        </div>
      </div>
    )
  }
}

class CaseStudiesList extends Component {
  componentWillMount() {
    this.props.getPage('case-studies')
    this.props.getCaseStudiesList()
  }

  render() {
    const {page, isFetching, notFound, caseStudies} = this.props
    const isAllFetching = isFetching || caseStudies.isFetching

    return (
      <BasePage
        className="caseStudiesPage"
        page={page}
        isFetching={isAllFetching}
        notFound={notFound}
      >
        <Helmet>
          <link rel="canonical" href="https://tsl.io/case-studies" />
          <meta property="og:url" href="https://tsl.io/case-studies" />
        </Helmet>
        {page && (
          <div>
            <div className="row container caseStudiesPage__intro">
              <div
                className="small-12 columns caseStudiesPage__intro__body"
                dangerouslySetInnerHTML={{__html: page.body}}
              />
            </div>

            <div className="row container caseStudiesPage__list">
              {caseStudies.list.map((caseStudy, i) => {
                const isRight = i % 2
                const showCTA = (i + 1) % 3 === 0 && i >= 2
                return (
                  <div>
                    <CaseStudyItem key={caseStudy.id} caseStudy={caseStudy} isRight={isRight} />
                    {showCTA && page.ctaform && (
                      <div className="caseStudiesPage__list__cta">
                        <CaseStudyCTAForm ctaform={page.ctaform} />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </BasePage>
    )
  }
}

CaseStudiesList.displayName = 'CaseStudiesList'

function mapStateToProps(state) {
  return {...state.pages, caseStudies: state.caseStudies}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getCaseStudiesList, getPage}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseStudiesList)
