import * as Types from './actions.types'

export function getPositionsList(params = {}) {
  return {type: Types.POSITIONS_LIST_GET, params}
}

export function getPositionsListSuccess(positions) {
  return {type: Types.POSITIONS_LIST_GET_SUCCESS, positions}
}

export function getPositionsListFailure(error) {
  return {type: Types.POSITIONS_LIST_GET_FAILURE, error}
}

export function getPosition(slug, params = {}) {
  return {type: Types.POSITION_GET, slug, params}
}

export function getPositionSuccess(position) {
  return {type: Types.POSITION_GET_SUCCESS, position}
}

export function getPositionFailure(error) {
  return {type: Types.POSITION_GET_FAILURE, error}
}
