import {takeEvery} from 'redux-saga'
import {call, put} from 'redux-saga/effects'

import PositionsApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* positionsFlow() {
  yield [takeEvery(Types.POSITIONS_LIST_GET, getPositionsList)]
  yield [takeEvery(Types.POSITION_GET, getPosition)]
}

function* getPositionsList(action) {
  try {
    const {params} = action
    const result = yield call(PositionsApi.getList, params)
    yield put(Actions.getPositionsListSuccess(result.results))
  } catch (error) {
    yield put(Actions.getPositionsListFailure(error))
  }
}

function* getPosition(action) {
  try {
    const {slug, params} = action
    const result = yield call(PositionsApi.getPosition, slug, params)
    yield put(Actions.getPositionSuccess(result))
  } catch (error) {
    yield put(Actions.getPositionFailure(error))
  }
}
