import {MuiThemeProvider, createMuiTheme, withStyles} from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import classNames from 'classnames'
import get from 'lodash/get'
import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import SwipeableViews from 'react-swipeable-views'
import {bindActionCreators, compose} from 'redux'

import settings from '../../../config/settings'
import BasePage from '../common/BasePage.jsx'
import LazyImage from '../common/LazyImage'
import {retinaOrNormalSrc} from '../utils/ImageUtils.js'
import RecentStories from './RecentStories.jsx'
import {getSkill} from './redux/actions'
import SkillDetailsCTAForm from './SkillDetailsCTAForm.jsx'

const theme = createMuiTheme({
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: '#24A2D1',
        borderRadius: '6px',
        height: 7,
      },
    },
    MuiTab: {
      root: {
        '&:hover': {
          borderBottom: '7px solid #24A2D1',
          // borderRadius: '6px',
        },
      },
      selected: {
        color: '#24A2D1 !important',
      },
    },
  },
})

const styles = (theme) => ({
  tabs: {
    padding: '25px 0 30px',
  },
  tabTitle: {
    color: '#606060',
    borderBottom: '7px solid #E7E7E7',
    // borderRadius: '6px',
    fontFamily: 'Source Sans Pro',
    fontSize: '24px',
    fontWeight: '600',
    letterSpacing: '0.21px',
    lineHeight: '31px',
  },

  keyServiceTitleWrapper: {
    [theme.breakpoints.up('lg')]: {
      width: '600px',
      left: '35%',
    },
  },
  keyServiceTitle: {
    fontFamily: 'Source Sans Pro',
    color: '#FFFFFF',
    fontSize: '42px',
    fontWeight: 600,
    letterSpacing: '0.38px',
    lineHeight: '58.8px',
    textAlign: 'center',
    padding: '10px',
    paddingTop: '50px',
    textTransform: 'capitalize',
  },
  keyServiceBlurb: {
    fontFamily: 'Source Sans Pro',
    color: '#FFFFFF',
    fontSize: '21px',
    letterSpacing: '0.19px',
    lineHeight: '29.4px',
    textAlign: 'left',
  },
  keyServiceBlurbWrapper: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '65px',
      paddingLeft: '50px',
    },
  },
  keyServiceImageWrapper: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '50px',
    },
  },
  meetingLink: {
    fontFamily: 'Source Sans Pro',
    color: '#FFFFFF',
    fontSize: '17px',
    fontWeight: 600,
    letterSpacing: '0.15px',
    lineHeight: '22px',
    textAlign: 'left',
    borderBottom: '4px solid #1400FF',
    cursor: 'pointer',
  },
  tabMeetinglink: {
    fontFamily: 'Source Sans Pro',
    color: '#606060',
    fontSize: '17px',
    fontWeight: 600,
    letterSpacing: '0.15px',
    lineHeight: '22px',
    textAlign: 'left',
    borderBottom: '4px solid #28A2D1',
    cursor: 'pointer',
  },
})

class SkillDetails extends React.Component {
  state = {
    index: 0,
  }

  componentWillMount() {
    this.props.getSkill(this.props.params.slug)
  }

  handleChange = (event, value) => {
    this.setState({
      index: value,
    })
  }

  handleChangeIndex = (index) => {
    this.setState({
      index,
    })
  }

  getMeetingLink() {
    const {skill} = this.props
    if (skill.keyServiceMeetingLink) {
      return skill.keyServiceMeetingLink
    }
    return 'https://go.tsl.io/meetings/cj9/meeting-requested-on-site'
  }

  render() {
    const {index} = this.state
    const {skill, isFetching, notFound, classes} = this.props
    return (
      <BasePage className="skillDetails" page={skill} isFetching={isFetching} notFound={notFound}>
        {skill && (
          <div>
            <Helmet>
              <title>{skill.title}</title>
              <link rel="canonical" href={`${settings.appBaseURL}/services/${skill.slug}`} />
              <meta property="og:url" content={`${settings.appBaseURL}/services/${skill.slug}`} />
            </Helmet>

            <div className="row container">
              <div className="pageDetails__intro skillDetails__intro">
                <h2>Introduction</h2>
                <p>{skill.intro}</p>
              </div>
            </div>

            <div className="skillDetails__weDo">
              <div className="row container">
                <div className="large-6 columns">
                  <div className="skillDetails__weDo__container skillDetails__weDo__container--what">
                    <h2>What we do</h2>
                    <div
                      className="skillDetails__weDo__container__content"
                      dangerouslySetInnerHTML={{__html: skill.what}}
                    />
                  </div>
                </div>

                <div className="large-6 columns">
                  <div className="skillDetails__weDo__container skillDetails__weDo__container--how">
                    <h2>How we do</h2>
                    <div
                      className="skillDetails__weDo__container__content"
                      dangerouslySetInnerHTML={{__html: skill.how}}
                    />
                  </div>
                </div>
              </div>
            </div>
            {skill.serviceTabTitle1 && (
              <div className="pageDetails__header">
                <div className="row container skillDetails__serviceTabs__container">
                  <div className={'clearfix ' + classes.tabs}>
                    <MuiThemeProvider theme={theme}>
                      <Tabs value={index} fullWidth onChange={this.handleChange}>
                        <Tab className={classes.tabTitle} label={skill.serviceTabTitle1} />
                        {skill.serviceTabTitle2 && (
                          <Tab className={classes.tabTitle} label={skill.serviceTabTitle2} />
                        )}
                        {skill.serviceTabTitle3 && (
                          <Tab className={classes.tabTitle} label={skill.serviceTabTitle3} />
                        )}
                      </Tabs>
                    </MuiThemeProvider>
                  </div>
                  <div className="clearfix skillDetails__serviceTabs__container--tabBody">
                    <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>
                      <div style={Object.assign({}, styles.slide)}>
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: get(skill, 'serviceTabBodyTitle1'),
                          }}
                        />
                        <p>{skill.serviceTabBody1}</p>
                      </div>
                      <div style={Object.assign({}, styles.slide)}>
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: get(skill, 'serviceTabBodyTitle2'),
                          }}
                        />
                        <p>{skill.serviceTabBody2}</p>
                      </div>
                      <div style={Object.assign({}, styles.slide)}>
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: get(skill, 'serviceTabBodyTitle3'),
                          }}
                        />
                        <p>{skill.serviceTabBody3}</p>
                      </div>
                    </SwipeableViews>
                    <a
                      className={classes.tabMeetinglink}
                      href="https://go.tsl.io/meetings/cj9/meeting-requested-on-site"
                    >
                      Schedule a free consultation{' '}
                      <span style={{fontSize: '30px', fontWeight: 800, color: '#28A2D1'}}>
                        &rarr;
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {skill.blogTopicId && (
              <RecentStories skill={skill} topicId={skill.blogTopicId} title={skill.title} />
            )}
            {skill.keyServiceTitle && (
              <div
                className="pageDetails__header"
                style={{minHeight: 640, background: `${skill.keyServiceBackgroudGradientCss}`}}
              >
                <div className={classes.keyServiceTitleWrapper}>
                  <h2 className={classes.keyServiceTitle}>{skill.keyServiceTitle}</h2>
                </div>
                <div className="row" style={{padding: '20px'}}>
                  <div className={'small-12 large-6 columns ' + classes.keyServiceImageWrapper}>
                    {skill.keyServiceImage && (
                      <LazyImage
                        src={retinaOrNormalSrc(skill.keyServiceImage)}
                        alt={`${skill.keyServiceTitle}`}
                      />
                    )}
                  </div>
                  <div className={'small-12 large-6 columns ' + classes.keyServiceBlurbWrapper}>
                    {skill.keyServiceBlurb && (
                      <div>
                        <p className={classes.keyServiceBlurb}>{skill.keyServiceBlurb}</p>
                        <p>
                          <a className={classes.meetingLink} href={this.getMeetingLink()}>
                            Schedule a free consultation{' '}
                            <span style={{fontSize: '30px', fontWeight: 800, color: '#1400FF'}}>
                              &rarr;
                            </span>
                          </a>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className={classNames({skillDetails__CTA_bottom_padding: !skill.blogTopicId})}>
              {skill.ctaform && <SkillDetailsCTAForm ctaform={skill.ctaform} />}
            </div>
          </div>
        )}
      </BasePage>
    )
  }
}

SkillDetails.displayName = 'SkillDetails'

function mapStateToProps(state) {
  return {...state.skills}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getSkill}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(SkillDetails)
