import isRetina from 'is-retina'

import imgLogoOG from '../../assets/logo_og.png'
import imgLogo from '../../assets/logo.svg'

export default class ImageUtils {
  static getAppLogoUrl() {
    return imgLogo
  }

  static getAppOGLogoUrl() {
    return imgLogoOG
  }
}

function is4k() {
  if (typeof window !== 'undefined' && window !== null) {
    return window.screen.height < window.screen.width
      ? window.screen.width > 3839
      : window.screen.height > 3839
  }
  return false
}

export function retinaOrNormalSrc(
  imageField,
  retinaField = 'retinaSize',
  normalField = 'normalSize',
  fourKField = 'fourkSize',
) {
  if (isRetina()) {
    return imageField[retinaField]
  }
  if (is4k()) {
    return imageField[fourKField]
  }
  return imageField[normalField]
}
