import * as Types from './actions.types'

export function getTeamMembers() {
  return {type: Types.TEAM_MEMBERS_GET}
}

export function getTeamMembersSuccess(members) {
  return {type: Types.TEAM_MEMBERS_GET_SUCCESS, members}
}

export function getTeamMembersFailure(error) {
  return {type: Types.TEAM_MEMBERS_GET_FAILURE, error}
}
