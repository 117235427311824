import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import settings from '../../../config/settings'
import Loading from '../common/Loading.jsx'
import NotFound from '../common/NotFound.Page.jsx'
import * as Actions from './redux/actions'

class Page extends React.Component {
  componentWillMount() {
    this.props.getPage(this.props.params.slug)
  }

  render() {
    const {page, isFetching} = this.props
    if (isFetching) return <Loading />
    if (!page) return <NotFound />
    return (
      <div className="ba-page">
        <Helmet title={page.title}>
          <link rel="canonical" href={`${settings.appBaseURL}/${page.slug}`} />
          <meta property="og:url" content={`${settings.apiBaseURL}/${page.slug}`} />
        </Helmet>
        <h1>{page.title}</h1>
        <div dangerouslySetInnerHTML={{__html: page.body}} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {...state.pages}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

Page.displayName = 'Page'
Page.contextTypes = {
  router: PropTypes.object.isRequired,
}
export default connect(mapStateToProps, mapDispatchToProps)(Page)
