import {RESPONSE_STATUS_INIT, RESPONSE_STATUS_SET} from './actions.responseStatus.types'

export default function responseStatus(state = getInitState(), action) {
  switch (action.type) {
    case RESPONSE_STATUS_SET:
      return {
        ...state,
        status: action.status,
      }
    case RESPONSE_STATUS_INIT:
      return getInitState()
    default:
      return state
  }
}

function getInitState() {
  return {
    status: 200,
  }
}
