import React from 'react'

import BaseCTAForm from '../common/BaseCTAForm.jsx'

class InnovationsCTAForm extends BaseCTAForm {
  render() {
    const {ctaform} = this.props
    return (
      <div className="cta skillDetails__cta clearfix">
        {this.state.formOpened ? (
          <div
            className="cta__form skillDetails__cta__form clearfix"
            id={this.state.formElementId}
          />
        ) : (
          <div className="row container">
            <p ClassName="title">{ctaform.title}</p>
            <h1 ClassName="description">{ctaform.description}</h1>
            <button onClick={this.openCTAForm}>Let's get started</button>
          </div>
        )}
      </div>
    )
  }
}

InnovationsCTAForm.displayName = 'InnovationsCTAForm'

export default InnovationsCTAForm
