import settings from '../../../config/settings'
import axios from '../api/axios'

export default class FeedApi {
  static getSocialFeed() {
    return axios.get(`${settings.apiBaseURL}/feed`)
  }

  static getRecentBlogPosts(params) {
    return axios.get(`${settings.apiBaseURL}/feed/recent-blog-posts`, {params})
  }
}
