import React from 'react'
import {Link} from 'react-router'

import LazyImage from '../common/LazyImage'
import {retinaOrNormalSrc} from '../utils/ImageUtils.js'

class InnovationItem extends React.Component {
  render() {
    const {innovation} = this.props
    return (
      <Link
        to={`/innovations/${innovation.slug}`}
        className="innovationsPage__list__item"
        style={{backgroundColor: innovation.primaryColor}}
      >
        <strong>{innovation.title}</strong>
        <span>{innovation.tagline}</span>
        <p>{innovation.description}</p>
        <LazyImage src={retinaOrNormalSrc(innovation.mockup)} alt={`${innovation.title} mockup`} />
      </Link>
    )
  }
}

InnovationItem.displayName = 'InnovationItem'

export default InnovationItem
