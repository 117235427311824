import classNames from 'classnames'
import forIn from 'lodash/forIn'
import React from 'react'
import ReactSwipe from 'react-swipe'

import Loading from '../common/Loading.jsx'

class TechsList extends React.Component {
  state = {opened: false, currentPos: 0}

  toggle = (e) => {
    e.preventDefault()
    this.setState({opened: !this.state.opened})
  }

  render() {
    const {techs} = this.props

    return (
      <div className={classNames('techs', {'techs--opened': this.state.opened})}>
        <div className="row container">
          <h2>
            <a>TECHNOLOGY STACK</a>
          </h2>
          {techs.isFetching ? (
            <Loading />
          ) : (
            <div
              className={classNames('techs__list clearfix', {
                'techs__list--opened': this.state.opened,
              })}
            >
              <ReactSwipe
                className="show-for-small-only"
                key={techs.list.length}
                swipeOptions={{
                  callback: (index) => {
                    this.setState({currentPos: index})
                  },
                }}
              >
                {techs.list.map((tech, i) => {
                  const sectionsMobile = []
                  forIn(tech.sections, (section, key) => {
                    sectionsMobile.push(<p key={key}>{section.bullets}</p>)
                  })
                  return (
                    <div key={i}>
                      <div className="techs__list__image">
                        <img src={tech.icon} width={100} height={100} alt={`${tech.title}`} />
                      </div>
                      <span className="techs_mobile_title">{tech.title}</span>
                      <div>{sectionsMobile}</div>
                    </div>
                  )
                })}
              </ReactSwipe>
              {techs.list.map((tech, i) => {
                const sectionsNormal = []
                forIn(tech.sections, (section, key) => {
                  sectionsNormal.push(<p key={key}>{section.bullets}</p>)
                })
                return (
                  <div key={i} className="techs__list__wrapper">
                    <div className="techs__list__image">
                      <img src={tech.icon} width={100} height={100} alt={`${tech.title}`} />
                    </div>
                    <div className="techs__list__text">
                      <span>{tech.title}</span>
                      <div className="techs__list__text__noramlText">{sectionsNormal}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
          <div className="show-for-small-only techs_dots">{this.renderDots()}</div>
        </div>
      </div>
    )
  }

  renderDots() {
    const totalAmountOfSlides = 5
    const dots = []
    const style = {
      width: 16,
      height: 16,
      marginRight: 10,
    }
    for (let i = 0; i <= totalAmountOfSlides; i++) {
      if (i === this.state.currentPos) {
        dots.push(
          <svg style={style}>
            <use xlinkHref="#fa-sprite--circle" />
          </svg>,
        )
      } else {
        dots.push(
          <svg style={style}>
            <use xlinkHref="#fa-sprite--circle-o" />
          </svg>,
        )
      }
    }
    return <div>{dots}</div>
  }
}

export default TechsList
