import without from 'lodash/without'

import {NotificationActionTypes} from './actions.notifications.types'

export default function notifications(state = [], action) {
  switch (action.type) {
    case NotificationActionTypes.CREATE_NOTIFICATION:
      return [...state, {...action.notification}]
    case NotificationActionTypes.DISMISS_NOTIFICATION:
      return without(state, action.notification)
    default:
      return state
  }
}
