import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import BasePage from '../common/BasePage.jsx'
import Loading from '../common/Loading.jsx'
import {getPage} from '../pages/redux/actions'
import InnovationItem from './InnovationItem.jsx'
import InnovationsCTAForm from './InnovationsCTAForm.jsx'
import {getInnovationsList} from './redux/actions'

class Innovations extends React.Component {
  componentWillMount() {
    this.props.getPage('innovations')
    this.props.getInnovationsList()
  }

  render() {
    const {page, innovations, isFetching, notFound} = this.props

    return (
      <BasePage className="innovations" page={page} isFetching={isFetching} notFound={notFound}>
        <Helmet>
          <link rel="canonical" href="https://tsl.io/innovations" />
          <meta property="og:url" href="https://tsl.io/innovations" />
        </Helmet>
        {page && (
          <div className="innovationsPage">
            <div className="innovationsPage__row container">
              <div className="innovationsPage__list">
                {innovations.isFetching ? (
                  <Loading />
                ) : innovations.list.size >= 6 ? (
                  innovations.list.map((innovation, i) => {
                    const ctaform = i === 2 && (
                      <InnovationsCTAForm class="row" ctaform={page.ctaform} />
                    )
                    return (
                      <div key={i}>
                        {ctaform}
                        <InnovationItem innovation={innovation} />
                      </div>
                    )
                  })
                ) : (
                  innovations.list.map((innovation, i) => {
                    return (
                      <div key={i}>
                        <InnovationItem innovation={innovation} />
                      </div>
                    )
                  })
                )}
              </div>
              <div className="skillDetails__CTA_bottom_padding">
                <InnovationsCTAForm ctaform={page.ctaform} />
              </div>
            </div>
          </div>
        )}
      </BasePage>
    )
  }
}

Innovations.displayName = 'Innovations'

function mapStateToProps(state) {
  return {
    ...state.pages,
    innovations: state.innovations,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getInnovationsList, getPage}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Innovations)
