import classNames from 'classnames'
import React from 'react'
import {Link} from 'react-router'

import Loading from '../common/Loading.jsx'

class SkillsListOpened extends React.Component {
  state = {opened: true}

  toggle = (e) => {
    e.preventDefault()
    this.setState({opened: !this.state.opened})
  }

  render() {
    const {skills} = this.props
    return (
      <div className={classNames('skills', {'skills--opened': this.state.opened})}>
        <div className="rowHexagons">
          <h2 className="skills-open_h2">What We Do</h2>
          {skills.isFetching ? (
            <Loading />
          ) : (
            <div
              className={classNames('skills__list clearfix', {
                'skills__list--opened': this.state.opened,
              })}
            >
              {skills.list.map((skill) => {
                return (
                  <Link key={skill.id} to={`/services/${skill.slug}`}>
                    <div className="hexagonContainer">
                      <div className="hexagonContainer2">
                        <div className="hexagon">
                          <span className="hexagonSpan" />
                        </div>
                      </div>
                      <div className="imageTitle">
                        <div className="skillImage">
                          <img src={skill.icon} width={100} height={100} alt={`${skill.title}`} />
                        </div>
                        <div className="skillTitle">
                          <p>{skill.title}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          )}
        </div>
        <h2 className="caseStudies">CASE STUDIES</h2>
      </div>
    )
  }
}

export default SkillsListOpened
