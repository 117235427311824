import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {Link} from 'react-router'
import {bindActionCreators} from 'redux'

import BasePage from '../common/BasePage.jsx'
import {getPage} from '../pages/redux/actions'
import {getPosition} from './redux/actions.js'
import SectionCTAForm from './SectionCTAForm.jsx'

class CareerDetails extends React.Component {
  componentWillMount() {
    this.props.getPage('careers')
    this.props.getPosition(this.props.params.slug, {expand: 'sections'})
  }

  render() {
    const {position, pages, isFetching, notFound} = this.props
    const isAllFetching = pages.isFetching || isFetching

    return (
      <BasePage
        className="careerDetails"
        page={position}
        isFetching={isAllFetching}
        notFound={notFound}
      >
        {position && pages.page && (
          <div>
            <Helmet>
              <link rel="canonical" href={`https://tsl.io/careers/${position.slug}`} />
              <meta property="og:url" content={`https://tsl.io/careers/${position.slug}`} />
            </Helmet>
            <div className="careerDetails__breadcrumbs">
              <div className="row container">
                <Link to="/careers">
                  <i className="fa fa-angle-left fa-2x" aria-hidden="true" /> Back to Careers
                </Link>
              </div>
            </div>

            <div className="careerDetails__description">
              <div className="row container">
                <div className="small-12 columns careerDetails__description__keywords">
                  {position.keywords.map((keyword, i) => {
                    return <span key={i}>{keyword}</span>
                  })}
                </div>
                <div className="small-12 columns careerDetails__description__location">
                  <strong>Location:</strong> {position.address}
                </div>
              </div>
            </div>

            <div className="careerDetails__sections">
              <div className="row container">
                <div
                  className="small-12 columns"
                  dangerouslySetInnerHTML={{__html: position.description}}
                />

                {position.sections.map((section) => {
                  return (
                    <div
                      className="small-12 columns careerDetails__sections__item"
                      key={section.id}
                    >
                      <h2>{section.title}</h2>
                      {section.body && (
                        <div
                          className="careerDetails__sections__item__body"
                          dangerouslySetInnerHTML={{__html: section.body}}
                        />
                      )}
                      {section.hubspotFormId && (
                        <SectionCTAForm ctaform={{hubspotFormId: section.hubspotFormId}} />
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </BasePage>
    )
  }
}

CareerDetails.displayName = 'CareerDetails'

function mapStateToProps(state) {
  return {...state.positions, pages: state.pages}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getPosition, getPage}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CareerDetails)
