import classNames from 'classnames'
import React from 'react'
import Helmet from 'react-helmet'

import {retinaOrNormalSrc} from '../utils/ImageUtils.js'
import Loading from './Loading.jsx'
import NotFound from './NotFound.Page.jsx'

class BasePageNew extends React.Component {
  render() {
    const {page, isFetching, notFound, className, customHeader} = this.props
    const hasCustomHeader = typeof customHeader !== 'undefined'
    if (notFound) return <NotFound />
    if (!page) return <Loading />

    const meta = [
      {property: 'og:title', content: page.metaTitle},
      {property: 'og:description', content: page.metaDescription},
      {property: 'description', content: page.metaDescription},
    ]
    if (page.ogImage) {
      meta.push({property: 'og:image', content: page.ogImage.fullSize})
    }

    return (
      <div className={classNames('clearfix pageDetailsNew', className)}>
        <Helmet title={page.metaTitle} meta={meta} />
        {hasCustomHeader ? (
          customHeader
        ) : (
          <div
            className="pageDetailsNew__header"
            style={
              page.headerImage && {backgroundImage: `url(${retinaOrNormalSrc(page.headerImage)})`}
            }
          >
            <div className="row container">
              <h1>{page.title}</h1>
              <p>{page.body}</p>
            </div>
          </div>
        )}
        {isFetching && (
          <div className="pageDetailsNew__loading">
            <Loading />
          </div>
        )}
        {this.props.children}
      </div>
    )
  }
}

BasePageNew.displayName = 'BasePageNew'

export default BasePageNew
