import dayjs from 'dayjs'
import React from 'react'
import {Link} from 'react-router'

export default class Footer extends React.Component {
  backToTop(e) {
    e.preventDefault()

    const cosParameter = window.scrollY / 2
    let scrollCount = 0
    let oldTimestamp = performance.now()

    function step(newTimestamp) {
      scrollCount += Math.PI / (1000 / (newTimestamp - oldTimestamp))
      if (scrollCount >= Math.PI) window.scrollTo(0, 0)
      if (window.scrollY === 0) return
      window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)))
      oldTimestamp = newTimestamp
      window.requestAnimationFrame(step)
    }

    window.requestAnimationFrame(step)
  }

  showCurrentYear() {
    return dayjs().format('YYYY')
  }

  render() {
    return (
      <footer className="ba-site-footer">
        <div className="clearfix footer-row">
          <div className="footer-not-mobile footer-larger-3 columns">
            <div className="ba-site-footer-links-left">
              <p className="ba-site-footer-links-left-bold">
                <Link to="/about-us">About Us</Link>
              </p>
              <p>
                <Link to="/culture">Culture</Link>
              </p>
              <p>
                <Link to="/careers">Careers</Link>
              </p>
              <p>
                <a href="https://blog.tsl.io/">Blog</a>
              </p>
              <p className="ba-site-footer-links-left-bold">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </p>
              <p>
                <a rel="me" href="https://fosstodon.org/@thesilverlogic">
                  Mastodon
                </a>
              </p>
            </div>
          </div>

          <div className="footer-not-mobile footer-larger-3 columns">
            <div className="ba-site-footer-links-middle">
              <p className="ba-site-footer-links-middle-bold">
                <Link to="/contact-us">Contact Us</Link>
              </p>
              <p>
                <a href="mailto:info@tsl.io">info@tsl.io</a>
              </p>
              <p>
                <a href="tel:5615692366">(561) 569-2366</a>
              </p>
              <p>
                <a href="https://www.google.com/maps/place/The+SilverLogic/@26.4034994,-80.1064823,17z/data=!3m2!4b1!5s0x88d91e2c889eb085:0x4c108f0111a33c53!4m5!3m4!1s0x88d8e1dff449eab7:0xe3b8582075db660c!8m2!3d26.4034946!4d-80.1042883">
                  6413 Congress Ave
                  <br /> Suite 130
                  <br /> Boca Raton, FL 33487
                </a>
              </p>
            </div>
          </div>
          <div className="footer-not-mobile footer-larger-4 columns">
            <div className="ba-site-footer-text ba-site-footer-text-right">
              <p className="ba-site-footer-title">Let’s Get Social</p>
              <div className="ba-site-footer-social">
                <a
                  href="https://www.facebook.com/TheSilverLogic/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className="footer-move-right">
                    <use xlinkHref="#fa-sprite--facebook-square" />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/silverlogic-llc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className="footer-move-right">
                    <use xlinkHref="#fa-sprite--linkedin-square" />
                  </svg>
                </a>
                <a
                  href="https://twitter.com/thesilverlogic"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className="footer-move-right2">
                    <use xlinkHref="#fa-sprite--twitter-square" />
                  </svg>
                </a>
                <a href="https://github.com/silverlogic" target="_blank" rel="noopener noreferrer">
                  <svg className="footer-move2">
                    <use xlinkHref="#fa-sprite--github-square" />
                  </svg>
                </a>
              </div>
              <p className="ba-site-footer-text-right__built">
                Built with{' '}
                <svg className="footer-heart-icon">
                  <use xlinkHref="#fa-sprite--heart" />
                </svg>
                &nbsp;in Boca Raton, FL
              </p>
              <p className="ba-site-footer-text-right__date">
                © {this.showCurrentYear()} The SilverLogic. All Rights Reserved.
              </p>
            </div>
          </div>
          <div className="footer-larger-3-text-right columns">
            <div className="ba-site-footer-text ba-site-footer-text-right">
              <p>
                <a href="mailto:info@tsl.io">info@tsl.io</a>
              </p>
              <p>
                <a href="tel:5615692366">(561) 569-2366</a>
              </p>
              <div className="footer-larger-3-text-right__mobile_address">
                <p>
                  <a href="https://www.google.com/maps/place/The+SilverLogic/@26.4015622,-80.102383,17z/data=!3m1!4b1!4m5!3m4!1s0x88d8e1dff449eab7:0xe3b8582075db660c!8m2!3d26.4015622!4d-80.1001943">
                    6413 Congress Ave
                    <br /> Suite 130
                    <br /> Boca Raton, FL 33487
                  </a>
                </p>
              </div>
              <p>
                <a
                  href="https://www.facebook.com/TheSilverLogic/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className="footer-move-right">
                    <use xlinkHref="#fa-sprite--facebook-square" />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/silverlogic-llc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className="footer-move-right">
                    <use xlinkHref="#fa-sprite--linkedin-square" />
                  </svg>
                </a>
                <a
                  href="https://twitter.com/thesilverlogic"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className="footer-move-right2">
                    <use xlinkHref="#fa-sprite--twitter-square" />
                  </svg>
                </a>
                <a href="https://github.com/silverlogic" target="_blank" rel="noopener noreferrer">
                  <svg className="footer-move2">
                    <use xlinkHref="#fa-sprite--github-square" />
                  </svg>
                </a>
              </p>
              <p className="mobile-footer">
                Built with{' '}
                <svg className="footer-heart-icon">
                  <use xlinkHref="#fa-sprite--heart" />
                </svg>
                &nbsp;in Boca Raton, FL
              </p>
              <p>©{this.showCurrentYear()} The SilverLogic. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.displayName = 'Footer'
