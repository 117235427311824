import get from 'lodash/get'
import React from 'react'
import Helmet from 'react-helmet'
import Masonry from 'react-masonry-component'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import settings from '../../../config/settings'
import BasePage from '../common/BasePage.jsx'
import {getTechsList} from '../techs/redux/actions'
import TechsList from '../techs/TechsList.jsx'
import CultureCTAForm from './CultureCTAForm.jsx'
import {getPage} from './redux/actions'

class Culture extends React.Component {
  componentWillMount() {
    this.props.getPage('culture')
    this.props.getTechsList()
  }

  render() {
    const {page, techs, isFetching, notFound} = this.props

    return (
      <BasePage className="culturePage" page={page} isFetching={isFetching} notFound={notFound}>
        <Helmet>
          <link rel="canonical" href={`${settings.appBaseURL}/culture`} />
          <meta property="og:url" href={`${settings.appBaseURL}/culture`} />
        </Helmet>
        {page && (
          <React.Fragment>
            <div className="row container">
              <div className="clearfix culturePage pageDetails__intro culturePage__joinUs">
                <h2>COME JOIN US</h2>
                <p className="techs_mobile_p">{page.body}</p>
                <Masonry options={{gutter: 90}} className="culture-grid">
                  <div className="culture-grid-item">
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: get(page, 'sections.snacksCaffeine.title'),
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: get(page, 'sections.snacksCaffeine.body'),
                      }}
                    />
                  </div>
                  <div className="culture-grid-item culture-grid-item__2">
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: get(page, 'sections.flexibleWorkHours.title'),
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: get(page, 'sections.flexibleWorkHours.body'),
                      }}
                    />
                  </div>
                  <div className="culture-grid-item culture-grid-item__3">
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: get(page, 'sections.chooseYourHoliday.title'),
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: get(page, 'sections.chooseYourHoliday.body'),
                      }}
                    />
                  </div>
                  <div className="culture-grid-item culture-grid-item__4">
                    <h4
                      dangerouslySetInnerHTML={{__html: get(page, 'sections.learningTree.title')}}
                    />
                    <p
                      dangerouslySetInnerHTML={{__html: get(page, 'sections.learningTree.body')}}
                    />
                  </div>
                </Masonry>
                <TechsList techs={techs} />
                <h2 className="cult-postition">POSITIONS</h2>
                <Masonry options={{gutter: 90}} className="culture-grid">
                  <div className="culture-grid-item">
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: get(page, 'sections.softwareEngineer.title'),
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: get(page, 'sections.softwareEngineer.body'),
                      }}
                    />
                  </div>
                  <div className="culture-grid-item culture-grid-item__2">
                    <h4
                      dangerouslySetInnerHTML={{__html: get(page, 'sections.productOwner.title')}}
                    />
                    <p
                      dangerouslySetInnerHTML={{__html: get(page, 'sections.productOwner.body')}}
                    />
                  </div>
                  <div className="culture-grid-item culture-grid-item__5">
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: get(page, 'sections.fullStackQaEngineer.title'),
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: get(page, 'sections.fullStackQaEngineer.body'),
                      }}
                    />
                  </div>
                  <div className="culture-grid-item culture-grid-item__6">
                    <h4
                      dangerouslySetInnerHTML={{__html: get(page, 'sections.scrumMaster.title')}}
                    />
                    <p dangerouslySetInnerHTML={{__html: get(page, 'sections.scrumMaster.body')}} />
                  </div>
                </Masonry>
              </div>
            </div>
            <div className="careersPage__interested">
              <div className="row container">
                <h2>Interested?</h2>
                <div
                  className="careersPage__interested__content"
                  dangerouslySetInnerHTML={{__html: page.body}}
                />
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="skillDetails__CTA_bottom_padding">
          {page && page.ctaform && <CultureCTAForm class="row" ctaform={page.ctaform} />}
        </div>
      </BasePage>
    )
  }
}

Culture.displayName = 'Culture'

function mapStateToProps(state) {
  return {
    ...state.pages,
    techs: state.techs,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getPage, getTechsList}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Culture)
