import Typography from '@material-ui/core/Typography'
import React from 'react'

import BaseCTAForm from '../common/BaseCTAForm.jsx'

class ContactUsCTAForm extends BaseCTAForm {
  componentDidMount() {
    this.setState({formElementId: 'hubspotForm'}, () => {
      this.openCTAForm()
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.ctaform.hubspotFormId !== prevProps.ctaform.hubspotFormId) {
      this.createHbsptForm(this.props.ctaform.hubspotFormId)
    }
  }

  render() {
    const {ctaform} = this.props
    return (
      <div className="contactUs__cta clearfix">
        <div className="row container">
          <div className="contactUs__cta__description">
            <Typography className="what_are_you_interested_in">{ctaform.title}</Typography>
          </div>
          <div className="contactUs__cta__form clearfix" id={this.state.formElementId} />
        </div>
      </div>
    )
  }
}

ContactUsCTAForm.displayName = 'ContactUsCTAForm'

export default ContactUsCTAForm
