import {Grid, withStyles} from '@material-ui/core'
import React from 'react'
import {Link} from 'react-router'

import DesignRush from '../../assets/designrushlogo.png'
import MiamiHerald from '../../assets/MiamiHerald.svg'
import prnLogo from '../../assets/prnLogo.svg'
import SunSentinel from '../../assets/SunSentinel.png'
import VoyageMIA from '../../assets/voyageMIA.svg'
import LazyImage from '../common/LazyImage'

const styles = (theme) => ({
  container: {
    margin: '0 auto',
    height: 300,
    paddingRight: '9%',
    paddingLeft: '9%',
    verticalAlign: 'middle',
    alignItems: 'center',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      height: '600px',
      paddingRight: 0,
      paddingLeft: 0,
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: '8%',
      paddingLeft: '8%',
    },
  },
  columnOne: {
    width: '90%',
    textAlign: 'center',
    margin: '0 auto',
  },
  headerColumn: {
    width: '100%',
    textAlign: 'center',
  },
  headerText: {
    color: '#28A2D1',
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 50,
    textDecoration: 'none',
  },
})

class SeenOn extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12} md={12}>
          <div className={classes.headerColumn}>
            <h5 className={classes.headerText}>SEEN ON</h5>{' '}
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.columnOne}>
            <Link to="https://www.miamiherald.com/" target="_blank" rel="noopener noreferrer">
              <LazyImage
                className={classes.miamiHerald}
                src={MiamiHerald}
                alt="Miami Herald Logo"
              />{' '}
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.columnOne}>
            <Link
              to="https://www.designrush.com/agency/it-services/managed-service-providers"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyImage className={classes.designrush} src={DesignRush} alt="DesignRush Logo" />{' '}
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.columnOne}>
            <Link to="https://www.sun-sentinel.com/" target="_blank" rel="noopener noreferrer">
              <LazyImage
                className={classes.sunSentinel}
                src={SunSentinel}
                alt="Sun-Sentinel Logo"
              />{' '}
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.columnOne}>
            <Link to="http://voyagemia.com/" target="_blank" rel="noopener noreferrer">
              <LazyImage className={classes.voyageMIA} src={VoyageMIA} alt="Voyage MIA Logo" />{' '}
            </Link>
          </div>
        </Grid>
      </Grid>
    )
  }
}

SeenOn.displayName = 'SeenOn'

export default withStyles(styles)(SeenOn)
