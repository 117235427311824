// import get from 'lodash/get'
import {Grid} from '@material-ui/core'
import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import settings from '../../../config/settings'
import FeaturedCaseStudies from '../case_studies/FeaturedCaseStudy.jsx'
import BasePageNew from '../common/BasePageNew.jsx'
import ProjectsCTAForm from '../projects/ProjectsCTAForm.jsx'
import {getSkillsList} from '../skills/redux/actions'
import SkillsListOpened from '../skills/SkillsListOpened.jsx'
import {getPage} from './redux/actions'

class WhatWeDo extends React.Component {
  componentWillMount() {
    this.props.getPage('services')
    this.props.getSkillsList()
  }

  render() {
    const {page, skills, isFetching, notFound} = this.props

    return (
      <BasePageNew className="whatWeDO" page={page} isFetching={isFetching} notFound={notFound}>
        <Helmet>
          <title>The SilverLogic | Services</title>
          <link rel="canonical" href={`${settings.appBaseURL}/services`} />
          <meta property="og:url" href={`${settings.appBaseURL}/services`} />
        </Helmet>
        {page && (
          <div>
            <div className="row container">
              <div className="whatWeDo__Page whatWeDo__TextRow">
                <h6> WHAT MAKES US</h6>
                <h5>DIFFERENT</h5>
              </div>
              <Grid container item justify="center">
                <Grid item lg={4} sm={12} className="whatWeDo__gridItem">
                  <hr
                    style={{
                      border: 'none',
                      width: '10%',
                      height: '5px',
                      color: '#28a2d1',
                      backgroundColor: '#28a2d1',
                    }}
                  />
                  <h6>Experience</h6>
                  <p>
                    We have empowered each team over the years building The SilverLogic across a
                    range of focuses, not only on technology but in project execution, customer
                    journey, design, process creating a thought leadership we add to each project.
                  </p>
                </Grid>
                <Grid item lg={4} sm={12} className="whatWeDo__gridItem">
                  <hr
                    style={{
                      border: 'none',
                      width: '10%',
                      height: '5px',
                      color: '#28a2d1',
                      backgroundColor: '#28a2d1',
                    }}
                  />
                  <h6>Transparency</h6>
                  <p>
                    Without it we would have never grown. We offer total transparency during the
                    process, full access to your project files and code source. With our platform
                    Approvd.io you can see the progress, cost, and final review of each feature.
                  </p>
                </Grid>
                <Grid item lg={4} sm={12} className="whatWeDo__gridItem">
                  <hr
                    style={{
                      border: 'none',
                      width: '10%',
                      height: '5px',
                      color: '#28a2d1',
                      backgroundColor: '#28a2d1',
                    }}
                  />
                  <h6>Quality & Agility</h6>
                  <p>
                    Our expertise are above and beyond being applied to each line of code delivered
                    after a rigorous QA. Our commitment is to deliver results in time, not just a
                    product creating a partnership ecosystem of feature development.
                  </p>
                </Grid>
              </Grid>
              <div className="tiltedBackground skills-page skills--opened whatWeDO__skills">
                <SkillsListOpened skills={skills} />
              </div>
            </div>
            <FeaturedCaseStudies />
          </div>
        )}
        <div className="whatWeDo__cta">
          {page && page.ctaform && <ProjectsCTAForm class="row" ctaform={page.ctaform} />}
        </div>
      </BasePageNew>
    )
  }
}

WhatWeDo.displayName = 'WhatWeDo'

function mapStateToProps(state) {
  return {
    ...state.pages,
    skills: state.skills,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getPage, getSkillsList}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WhatWeDo)
