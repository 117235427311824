import get from 'lodash/get'
import React from 'react'

import LazyImage from '../common/LazyImage'

class HomeCTAForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.hubSpotFormLoaded = false

    this.loadHubSpotForm = this.loadHubSpotForm.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.loadHubSpotForm)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadHubSpotForm)
  }

  loadHubSpotForm() {
    if (this.hubSpotFormLoaded) {
      return
    }
    this.hubSpotFormLoaded = true

    const {ctaform} = this.props
    const hubspotFormId = get(ctaform, 'hubspotFormId')

    if (hubspotFormId) {
      const script = document.createElement('script')
      script.src = 'https://js.hsforms.net/forms/v2.js'
      script.async = true
      document.body.appendChild(script)

      const ifIE = document.createElement('div')
      ifIE.innerHTML =
        '<!--[if lte IE 8]><script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script><![endif]-->'
      document.body.appendChild(ifIE)

      script.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            css: '',
            portalId: '3838537',
            formId: hubspotFormId,
            target: '#hubspotForm',
          })
        }
      })
    }
  }

  render() {
    const {ctaform} = this.props

    return (
      <div className="ba-home__cta clearfix">
        <div className="row container">
          <div className="ba-home__cta__description">
            <LazyImage src={ctaform.image} alt="CTA" />
            <div className="ba-home__cta__description__text">
              <h5>{ctaform.title}</h5>
              <p>{ctaform.description}</p>
            </div>
          </div>

          <div className="ba-home__cta__form clearfix" id="hubspotForm" />
        </div>
      </div>
    )
  }
}

HomeCTAForm.displayName = 'HomeCTAForm'

export default HomeCTAForm
