import {call, put} from 'redux-saga/effects'
import {takeEvery} from 'redux-saga'
import PagesApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as ResponseActions from '../../common/redux/actions.responseStatus'

export default function* pageFlow() {
  yield [takeEvery(Types.PAGE_GET, getPage)]
}

function* getPage(action) {
  const {slug} = action
  try {
    const result = yield call(PagesApi.getPage, slug)
    yield put(Actions.getPageSuccess(result))
  } catch (error) {
    yield put(Actions.getPageFailure(error))
    yield put(ResponseActions.setResponseStatus(404))
  }
}
