import {call, put} from 'redux-saga/effects'
import {takeEvery} from 'redux-saga'
import HomeApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* homeFlow() {
  yield [takeEvery(Types.HOME_GET, getHome)]
}

function* getHome() {
  try {
    const result = yield call(HomeApi.getHomeByName, 'home2')
    yield put(Actions.getHomeSuccess(result))
  } catch (error) {
    yield put(Actions.getHomeFailure(error))
  }
}
