import {fork} from 'redux-saga/effects'

import careersFlow from './careers/redux/sagas'
import caseStudiesFlow from './case_studies/redux/sagas'
import errorFlow from './common/redux/sagas.errors'
import feedFlow from './feed/redux/sagas'
import homeFlow from './home/redux/sagas'
import innovationsFlow from './innovations/redux/sagas'
import pageFlow from './pages/redux/sagas'
import projectsFlow from './projects/redux/sagas'
import skillsFlow from './skills/redux/sagas'
import tagsFlow from './tags/redux/sagas'
import teamMembersFlow from './team_members/redux/sagas'
import techsFlow from './techs/redux/sagas'

export default function* root() {
  yield [
    fork(errorFlow),
    fork(homeFlow),
    fork(pageFlow),
    fork(skillsFlow),
    fork(feedFlow),
    fork(projectsFlow),
    fork(teamMembersFlow),
    fork(innovationsFlow),
    fork(techsFlow),
    fork(careersFlow),
    fork(tagsFlow),
    fork(caseStudiesFlow),
  ]
}
