import React from 'react'

import TeamMemberCell from './TeamMemberCell.jsx'

const TeamMembersGrid = (props) => {
  const {members} = props
  const featuredMembers = []
  const employeeMembers = []

  const noFeaturedMembers = []
  members.forEach((value) => {
    const cell = <TeamMemberCell key={value.id} member={value} />
    if (value.isFeatured && !value.isNormalEmployee) {
      featuredMembers.push(cell)
    } else if (value.isNormalEmployee) {
      employeeMembers.push(cell)
    } else {
      noFeaturedMembers.push(cell)
    }
  })

  return (
    <div className="team-members-container">
      <h2>THE TEAM</h2>
      <div className="team-members-grid">{featuredMembers}</div>
      <div className="team-members-grid-employee">{employeeMembers}</div>
      <div className="team-members-grid only-mobile-hidden">{noFeaturedMembers}</div>
      <div className="titles-list only-mobile-visible">
        <p>37 Software Engineers</p>
        <p>5 Designers</p>
        <p>5 Full Stack QA Engineers</p>
        <p>8 Product Owners</p>
        <p>3 Scrum Master</p>
        <p>2 Marketing & Operations Professionals</p>
        <p>4 Directors</p>
        <p className="and-you-label">and you?</p>
      </div>
    </div>
  )
}

export default TeamMembersGrid
