import get from 'lodash/get'
import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'

import Footer from './common/Footer.jsx'
import Header from './common/Header.jsx'

const headerlessLocations = ['confirm-email']
const footerlessLocations = ['confirm-email']

class App extends React.Component {
  constructor() {
    super()

    this.state = {
      showHeader: true,
      showFooter: true,
    }
  }

  componentDidMount() {
    this.setHeaderFooterState(this.props.location)
  }

  componentWillReceiveProps(nextProps) {
    const hasLocationChanged =
      get(nextProps, 'location.pathname') !== get(this.props, 'location.pathname')
    if (hasLocationChanged) {
      this.setHeaderFooterState(nextProps.location)
    }
  }

  setHeaderFooterState(location) {
    const showHeader = headerlessLocations.indexOf(location.pathname.split('/')[1]) < 0
    const showFooter = footerlessLocations.indexOf(location.pathname.split('/')[1]) < 0

    if (showHeader !== this.state.showHeader || showFooter !== this.state.showFooter) {
      this.setState({showHeader, showFooter})
    }
  }

  render() {
    const {auth, profile, location} = this.props
    const {showHeader, showFooter} = this.state

    const defaultMetaDescription =
      'We are a value-driven, client-focused custom software development company leveraging efficient problem-solving skills to upgrade and grow your business.\n'

    return (
      <div className="ba-site">
        <Helmet
          defaultTitle="The SilverLogic | Custom Software Development"
          meta={[
            {name: 'twitter:card', content: 'summary_large_image'},
            {name: 'twitter:site', content: '@thesilerlogic'},
            {name: 'twitter:image', content: 'https://tsl.io/assets/logo_og.png'},
            {name: 'twitter:label1', content: '☎️'},
            {name: 'twitter:data1', content: '(561) 569-2366'},
            {name: 'twitter:label2', content: '📧'},
            {name: 'twitter:data2', content: 'info@tsl.io'},
            {property: 'og:title', content: 'The SilverLogic | Custom Software Development'},
            {property: 'og:site_name', content: 'The SilverLogic'},
            {property: 'og:image', content: 'https://tsl.io/assets/logo_og.png'},
            {property: 'og:type', content: 'website'},
            {property: 'og:description', content: defaultMetaDescription},
            {name: 'description', content: defaultMetaDescription},
          ]}
        >
          <html lang="en" />
        </Helmet>
        {showHeader ? (
          <Header auth={auth} location={location} profile={profile} onHomeClick={() => {}} />
        ) : null}
        <div className="ba-main">{this.props.children}</div>
        {showFooter ? <Footer /> : null}
      </div>
    )
  }
}

App.displayName = 'App'

function mapStateToProps(state) {
  return {
    auth: state.auth,
    profile: get(state.profile, 'profile', null),
  }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(App)
