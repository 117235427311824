import {routerReducer} from 'react-router-redux'
import {combineReducers} from 'redux'

import positions from './careers/redux/reducers'
import caseStudies from './case_studies/redux/reducers'
import notifications from './common/redux/reducers.notifications'
import responseStatus from './common/redux/reducers.responseStatus'
import feed from './feed/redux/reducers'
import home from './home/redux/reducers'
import innovations from './innovations/redux/reducers'
import pages from './pages/redux/reducers'
import projects from './projects/redux/reducers'
import skills from './skills/redux/reducers'
import tags from './tags/redux/reducers'
import teamMembers from './team_members/redux/reducers'
import techs from './techs/redux/reducers'

const rootReducer = combineReducers({
  routing: routerReducer,
  responseStatus,
  home,
  notifications,
  pages,
  skills,
  feed,
  projects,
  teamMembers,
  innovations,
  techs,
  positions,
  tags,
  caseStudies,
})

export default rootReducer
