import settings from '../../../config/settings'
import axios from '../api/axios'

export default class InnovationsApi {
  static getInnovation(slug, params) {
    return axios.get(`${settings.apiBaseURL}/innovations/${slug}`, {params})
  }

  static getList(params) {
    return axios.get(`${settings.apiBaseURL}/innovations`, {params})
  }
}
