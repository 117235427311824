import * as Types from './actions.types'

export function getProjectsList(params = {}) {
  return {type: Types.PROJECTS_LIST_GET, params}
}

export function getProjectsListSuccess(projects) {
  return {type: Types.PROJECTS_LIST_GET_SUCCESS, projects}
}

export function getProjectsListFailure(error) {
  return {type: Types.PROJECTS_LIST_GET_FAILURE, error}
}

export function getProject(slug, params = {}) {
  return {type: Types.PROJECT_GET, slug, params}
}

export function getProjectSuccess(project) {
  return {type: Types.PROJECT_GET_SUCCESS, project}
}

export function getProjectFailure(error) {
  return {type: Types.PROJECT_GET_FAILURE, error}
}
