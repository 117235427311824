import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React from 'react'

import facebookLogo from '../../assets/social-icons/facebook.png'
import instagramLogo from '../../assets/social-icons/instagram.png'
import twitterLogo from '../../assets/social-icons/twitter.png'
import AspectRatio from '../common/AspectRatio.jsx'
import LazyImage from '../common/LazyImage'
// import FbVideo from '../common/FbVideo.jsx';
import TwitterVideo from '../common/TwitterVideo.jsx'

dayjs.extend(utc)

class SocialFeedCell extends React.Component {
  getLogo() {
    const {source} = this.props.post
    let logo = null
    switch (source) {
      case 'facebook':
        logo = facebookLogo
        break
      case 'instagram':
        logo = instagramLogo
        break
      default:
    }
    return logo ? <LazyImage className="source-logo" src={logo} alt={source} /> : null
  }

  getTopView() {
    const {post} = this.props
    const {image, video} = post
    if (video && post.source) {
      return this.getVideo(post)
    }
    if (image && post.source !== 'facebook' && !image.includes('tweet_video_thumb')) {
      return <img className="post-image rounded" src={post.image} alt={post.title} />
    }
    return null
  }

  getVideo() {
    // commented out facebook sources
    // const {image, video, source} = this.props.post;
    const {video, source} = this.props.post

    let component = null

    // if (source === 'facebook') {
    //   component = (
    //     <AspectRatio ratio={16 / 9}>
    //       <FbVideo url={video.url} autoPlay={false} width="1000" placeholder={image}/>
    //     </AspectRatio>
    //   );
    // } else if (source === 'twitter') {
    if (source === 'twitter') {
      const {aspectRatio, id} = video
      component = (
        <AspectRatio ratio={aspectRatio}>
          <TwitterVideo id={id} className="twitter-video-container" />
        </AspectRatio>
      )
    } else {
      component = (
        <video className="post-image rounded" width="100%" controls>
          <source src={video.url} />
          <track kind="captions" />
        </video>
      )
    }

    return component
  }

  getTextBox() {
    const {post} = this.props
    switch (post.source) {
      case 'twitter':
        return this.getTwitterBox(post)
      case 'instagram':
        return null
      default:
        // return this.getDefaultTextBox(post);
        return null
    }
  }

  getDefaultTextBox() {
    const {post} = this.props

    if ((!post.title || post.title.length === 0) && (!post.text || post.text.length === 0)) {
      return null
    }

    return (
      <div className="feed-cell-text-box rounded">
        <p className="feed-title">{post.title}</p>
        <p className="feed-date">{dayjs.utc(post.date).format('MM/DD/YYYY')}</p>
        <p className="feed-text">{post.text}</p>
      </div>
    )
  }

  getTwitterBox() {
    const {post} = this.props
    const {info} = post

    return (
      <div className="feed-cell-twitter-box rounded">
        <div className="feed-cell-twitter-box_image-column">
          <LazyImage src={twitterLogo} alt={post.source} />
        </div>
        <div className="feed-cell-twitter-box_text-column">
          {info.retweetedBy ? (
            <p className="feed-retweet">{`@${info.retweetedBy.username} Retweeted`}</p>
          ) : null}
          <p>
            <span className="feed-author-name">{info.author.fullName}</span>
            <span className="feed-author-username">{`@${info.author.username}`}</span>
            <span className="feed-date">{dayjs.utc(post.date).format('MMM D')}</span>
          </p>
          <p className="feed-text">{post.text}</p>
        </div>
      </div>
    )
  }

  render() {
    const {url} = this.props.post
    const topView = this.getTopView()
    const logo = this.getLogo()

    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className="feed-cell-image-box rounded">
          {topView}
          {logo}
        </div>
        {this.getTextBox()}
      </a>
    )
  }
}

export default SocialFeedCell
