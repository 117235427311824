import {withStyles} from '@material-ui/core'
import cx from 'classnames'
import React from 'react'
import Slider from 'react-slick'

import Airpros from '../../assets/trusted-by/airprosLogo.png'
import BodyDetails from '../../assets/trusted-by/bodyDetailsLogo.png'
import Chipotle from '../../assets/trusted-by/chipotleLogo.png'
import Codestream from '../../assets/trusted-by/codeStreamLogo.png'
import DeliveryDudes from '../../assets/trusted-by/deliverydudesLogo.png'
import Fortress from '../../assets/trusted-by/fortLogo.png'
import FSR from '../../assets/trusted-by/fsrLogo.png'
import FyzicalFitness from '../../assets/trusted-by/fyzicalFitnessLogo.png'
import Inspected from '../../assets/trusted-by/inspectedLogo.png'
import Jinglz from '../../assets/trusted-by/jinglzLogo.png'
import Krain from '../../assets/trusted-by/kRainLogo.png'
import MoreVisibility from '../../assets/trusted-by/moreVisibilityLogo.png'
import Neo4j from '../../assets/trusted-by/neo4jLogo.png'
import Popeyes from '../../assets/trusted-by/popeyesLogo.png'
import Rewired from '../../assets/trusted-by/rewiredLogo.png'
import Tiendas from '../../assets/trusted-by/tiendasLogo.png'
import TPO from '../../assets/trusted-by/tpoLogo.png'
import Transunion from '../../assets/trusted-by/transunionLogo.png'
import Visa from '../../assets/trusted-by/visaLogo.png'
import Vupulse from '../../assets/trusted-by/vupulseLogo.png'
import ZF from '../../assets/trusted-by/zfLogo.png'
import LazyImage from '../common/LazyImage'

const styles = (theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    height: '250px',
    width: '100%',
    marginTop: '-70px',
    '-webkit-backface-visibility': 'hidden' /* Safari */,
    backfaceVisibility: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '200px',
      marginTop: '-70px',
    },
  },
  containerBottom: {
    backgroundColor: '#FFFFFF',
    height: '200px',
    width: '100%',
    marginTop: '-3px',
    '-webkit-backface-visibility': 'hidden' /* Safari */,
    backfaceVisibility: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '240px',
      marginTop: '-50px',
      marginBottom: '-50px',
    },
  },
  imageContainer: {
    height: '200px',
    width: '200px',
    marginBottom: '10px',
  },
  text: {
    '& h5': {
      paddingTop: 25,
      color: '#28A2D1',
      textAlign: 'center',
      fontSize: '30px',
      fontWeight: '600',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '-30px',
      },
    },
  },
  imageLarge: {
    maxWidth: '45%',
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    filter: 'grayscale(1)',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      filter: 'none !important',
    },
    [theme.breakpoints.down('sm')]: {
      filter: 'grayscale(1)',
      maxWidth: '30%',
      '&:hover': {
        filter: 'grayscale(1)',
      },
    },
  },
  imageLarge2: {
    maxWidth: '55%',
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    filter: 'grayscale(1)',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      filter: 'none !important',
    },
    [theme.breakpoints.down('sm')]: {
      filter: 'grayscale(1)',
      maxWidth: '45%',
      '&:hover': {
        filter: 'grayscale(1)',
      },
    },
  },
  imagePopeyes: {
    maxWidth: '45%',
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    filter: 'grayscale(1)',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      filter: 'none !important',
    },
    [theme.breakpoints.down('sm')]: {
      filter: 'grayscale(1)',
      maxWidth: '30%',
      '&:hover': {
        filter: 'grayscale(1)',
      },
    },
  },
  image: {
    maxWidth: '75%',
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    filter: 'grayscale(1)',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      filter: 'none !important',
    },
    [theme.breakpoints.down('sm')]: {
      filter: 'grayscale(1)',
      maxWidth: '65%',
      '&:hover': {
        filter: 'grayscale(1)',
      },
    },
  },
})

class ClientBarOurWork extends React.Component {
  render() {
    const {classes} = this.props

    const settings = {
      dots: false,
      infinite: true,
      speed: 9000,
      useCSS: true,
      autoplaySpeed: 0,
      cssEase: 'linear',
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      rtl: false,
      centerMode: true,
      accessibility: false,
      touchMove: false,
      pauseOnHover: false,
      pauseOnFocus: false,
      pauseOnDotsHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            autoplaySpeed: 5000,
            speed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            touchMove: false,
            waitForAnimate: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            pauseOnDotsHover: false,
            swipe: false,
          },
        },
      ],
    }
    const settings2 = {
      dots: false,
      infinite: true,
      speed: 9000,
      useCSS: true,
      autoplaySpeed: 0,
      cssEase: 'linear',
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      rtl: true,
      centerMode: true,
      accessibility: false,
      touchMove: false,
      pauseOnHover: false,
      pauseOnFocus: false,
      pauseOnDotsHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            autoplaySpeed: 5000,
            speed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            touchMove: false,
            waitForAnimate: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            pauseOnDotsHover: false,
            swipe: false,
          },
        },
      ],
    }

    return (
      <div>
        <style>{cssstyle}</style>
        <div className={classes.container}>
          <Slider {...settings}>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.imageLarge)} src={Chipotle} alt="Chipotle Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.image)} src={BodyDetails} alt="Body Details Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.image)} src={Visa} alt="Visa Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.imageLarge2)} src={ZF} alt="ZF Friedrichshafen" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.imageLarge2)}
                src={DeliveryDudes}
                alt="Delivery Dudes"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.imageLarge)} src={FSR} alt="FSR Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.image)} src={Transunion} alt="Transunion Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.image)} src={Vupulse} alt="Vupulse Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.image)} src={Fortress} alt="Fortress Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.image)} src={Neo4j} alt="Neo4j Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.imagePopeyes)} src={Popeyes} alt="Popeyes Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.imageLarge)} src={Inspected} alt="Inspected Logo" />
            </div>
          </Slider>
        </div>
        <div className={classes.containerBottom}>
          <Slider {...settings2}>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.image)}
                src={FyzicalFitness}
                alt="Fyzical Fitness Logo"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.imageLarge)} src={Tiendas} alt="Tiendas Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.imageLarge2)} src={Jinglz} alt="Jinglz Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.imageLarge)} src={Rewired} alt="Rewired Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.image)} src={Airpros} alt="Airpros Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.image)} src={Codestream} alt="Codestream Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.image)} src={TPO} alt="TPO Logo" />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage
                className={cx(classes.image)}
                src={MoreVisibility}
                alt="More Visibility Logo"
              />
            </div>
            <div className={classes.imageContainer}>
              <LazyImage className={cx(classes.imageLarge)} src={Krain} alt="Krain Logo" />
            </div>
          </Slider>
        </div>
      </div>
    )
  }
}
const cssstyle = `
.slick-slide, .slick-slide *{ outline: none !important; };

`

ClientBarOurWork.displayName = 'ClientBarOurWork'

export default withStyles(styles)(ClientBarOurWork)
