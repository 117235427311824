import * as Types from './actions.types'

export function getHome() {
  return {type: Types.HOME_GET}
}

export function getHomeSuccess(home) {
  return {type: Types.HOME_GET_SUCCESS, home}
}

export function getHomeFailure(error) {
  return {type: Types.HOME_GET_FAILURE, error}
}
