import classNames from 'classnames'
import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router'
import Slider from 'react-slick'
import {bindActionCreators} from 'redux'

import LazyImage from '../common/LazyImage'
import Loading from '../common/Loading.jsx'
import {retinaOrNormalSrc} from '../utils/ImageUtils.js'
import {getProjectsList} from './redux/actions'

class ProjectItem extends React.Component {
  render() {
    const {project} = this.props
    return (
      <React.Fragment>
        {project.whatWeDid ? (
          <div className="projects-item large 4 columns">
            <Link
              to={`/project/${project.slug}`}
              className={classNames('projects__list__item', {})}
              style={{backgroundColor: '#F2F2F2'}}
            >
              <div className="projects-item__mainContent">
                <h3 className="gridHeader">{project.title}</h3>
                <div style={{height: 70}}>
                  <span>{project.tagline}</span>
                </div>
                <p>{project.description}</p>
                <div className="projects-item__imageContainer">
                  <LazyImage
                    src={retinaOrNormalSrc(project.mockup)}
                    alt={`${project.title} mockup`}
                  />
                </div>
              </div>
              <div className="projects-item__readMore">
                <Link to={`/project/${project.slug}`} className="readMoreButton">
                  Read more
                </Link>
              </div>
            </Link>
          </div>
        ) : (
          <div className="projects-item large 4 columns">
            <div
              className={classNames('projects__list__item', {})}
              style={{backgroundColor: '#F2F2F2'}}
            >
              <div className="projects-item__mainContent">
                <h3 className="gridHeader">{project.title}</h3>
                <div style={{height: 70}}>
                  <span>{project.tagline}</span>
                </div>
                <p>{project.description}</p>
                <div className="projects-item__imageContainer">
                  <LazyImage
                    src={retinaOrNormalSrc(project.mockup)}
                    alt={`${project.title} mockup`}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

class RecentProjects extends React.Component {
  state = {opened: false}

  componentWillMount() {
    this.props.getProjectsList({is_featured: 1})
  }

  toggle = (e) => {
    e.preventDefault()
    this.setState({opened: !this.state.opened})
  }

  render() {
    const {projects} = this.props
    const settings2 = {
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: false,
      rtl: false,
      centerMode: false,
      accessibility: false,
      touchMove: false,
      pauseOnHover: false,
      pauseOnFocus: false,
      pauseOnDotsHover: false,
      responsive: [
        {
          breakpoint: 1441,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            touchMove: false,
            waitForAnimate: false,
            pauseOnHover: false,
            pauseOnFocus: false,
            pauseOnDotsHover: false,
            swipe: false,
          },
        },
      ],
    }
    return (
      <React.Fragment>
        {typeof window !== 'undefined' && window.innerWidth < 500 ? (
          <div className={classNames('projects', {'projects--opened': this.state.opened})}>
            <div className="row container">
              <h2>
                <a onClick={this.toggle}>
                  DELIVERING INNOVATIVE SOLUTIONS&nbsp;
                  <svg style={{width: 12, height: 8}} className="fa-caret-down only-mobile-visible">
                    <use xlinkHref="#fa-sprite--caret-down" />
                  </svg>
                </a>
              </h2>
              <div
                className="medium-12 large-12  columns items"
                style={{
                  justifyContent: 'center',
                }}
              >
                <style>{cssstyle}</style>
                <div>
                  <div
                    style={{height: 545}}
                    className={classNames('projects__list clearfix', {
                      'projects__list--opened': this.state.opened,
                    })}
                  >
                    {projects.isFetching ? (
                      <Loading />
                    ) : (
                      projects.list.map((project) => {
                        return <ProjectItem key={project.id} project={project} />
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={classNames('projects', {'projects--opened': this.state.opened})}>
            <div className="row container">
              <h2>
                <a onClick={this.toggle}>
                  DELIVERING INNOVATIVE SOLUTIONS&nbsp;
                  <svg style={{width: 12, height: 8}} className="fa-caret-down only-mobile-visible">
                    <use xlinkHref="#fa-sprite--caret-down" />
                  </svg>
                </a>
              </h2>
              <div
                className="medium-12 large-12  columns items"
                style={{
                  justifyContent: 'center',
                }}
              >
                <style>{cssstyle}</style>
                <div>
                  <div
                    style={{height: 545}}
                    className={classNames('projects__list clearfix', {
                      'projects__list--opened': this.state.opened,
                    })}
                  >
                    <Slider {...settings2} className="projects__list__carousel">
                      {projects.isFetching ? (
                        <Loading />
                      ) : (
                        projects.list.map((project) => {
                          return <ProjectItem key={project.id} project={project} />
                        })
                      )}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}
const cssstyle = `
.slick-slide, .slick-slide *{ outline: none !important; };
`

RecentProjects.displayName = 'RecentProjects'

function mapStateToProps(state) {
  return {
    projects: state.projects,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getProjectsList}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentProjects)
