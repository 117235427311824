import classNames from 'classnames'
import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {Link} from 'react-router'
import {bindActionCreators} from 'redux'

import settings from '../../../config/settings'
import BasePage from '../common/BasePage.jsx'
import LazyImage from '../common/LazyImage'
import {retinaOrNormalSrc} from '../utils/ImageUtils.js'
import {getProject} from './redux/actions'

class ProjectDetails extends React.Component {
  componentWillMount() {
    this.props.getProject(this.props.params.slug, {expand: 'components,sections'})
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params.slug !== nextProps.params.slug) {
      this.props.getProject(nextProps.params.slug, {expand: 'components,sections'})
    }
  }

  render() {
    const {project, isFetching, notFound} = this.props
    const triangleSizes = ['largest', 'medium', 'small', 'medium', 'large', 'small']

    let customHeader = null
    if (project) {
      customHeader = (
        <div className="projectDetails__header" style={{backgroundColor: project.primaryColor}}>
          <div className="text">
            <h1>{project.title}</h1>
            <span className="show-for-medium-up">{project.tagline}</span>
          </div>
          <div className="mockup">
            <LazyImage
              src={retinaOrNormalSrc(project.mockup, 'detailsRetinaSize', 'detailsNormalSize')}
              alt={`${project.title} mockup`}
            />
          </div>
        </div>
      )
    }

    return (
      <BasePage
        className="projectDetails"
        page={project}
        isFetching={isFetching}
        notFound={notFound}
        customHeader={customHeader}
      >
        {project && (
          <div>
            <Helmet>
              <title>{project.title}</title>
              <link rel="canonical" href={`${settings.appBaseURL}/project/${project.slug}`} />
              <meta property="og:url" content={`${settings.appBaseURL}/project/${project.slug}`} />
            </Helmet>
            <style type="text/css">
              {`.projectDetails__section__image::before {
              background-color: ${project.primaryColor};
            }`}
            </style>

            <div className="row container">
              <div className="large-6 columns projectDetails__components">
                <h2>Components Used</h2>
                <ul>
                  {project.components.map((component) => {
                    return <li key={component.id}>{component.title}</li>
                  })}
                </ul>
              </div>
              <div className="large-6 columns projectDetails__whatWeDid">
                <h2>What We Did</h2>
                <p dangerouslySetInnerHTML={{__html: project.whatWeDid}} />
              </div>
            </div>

            <div className="projectDetails__howWeDid">
              <div className="row container">
                <div className="medium-12 columns">
                  <h2>How We Did It</h2>
                  <p dangerouslySetInnerHTML={{__html: project.howWeDid}} />
                </div>
              </div>
            </div>

            {project.sections.map((section, i) => {
              const classSize = triangleSizes[i % triangleSizes.length]
              const isRight = i % 2
              const imageClassName = classNames('large-6 columns projectDetails__section__image', {
                'projectDetails__section__image--right': isRight,
                ['projectDetails__section__image--' + classSize]: true,
                ['projectDetails__section__image--' + classSize + '--right']: isRight,
              })
              return (
                <div className="projectDetails__section" key={section.id}>
                  <div className="row container">
                    <div className={imageClassName}>
                      <LazyImage
                        src={retinaOrNormalSrc(section.image)}
                        alt={`${section.title} mockup`}
                      />
                    </div>
                    <div className="large-6 columns projectDetails__section__content">
                      <h2>{section.title}</h2>
                      <p dangerouslySetInnerHTML={{__html: section.body}} />
                    </div>
                  </div>
                </div>
              )
            })}

            <div className="projectDetails__navigation">
              <div className="row container">
                <div className="small-6 columns end projectDetails__navigation__prev">
                  {project.previous && (
                    <Link to={`/project/${project.previous.slug}`} title={project.previous.title}>
                      <svg style={{width: 30, height: 30}}>
                        <use xlinkHref="#fa-sprite--angle-left" />
                      </svg>{' '}
                      <span className="show-for-medium-up">PREVIOUS PROJECT</span>
                    </Link>
                  )}
                </div>
                <div className="small-6 columns projectDetails__navigation__next">
                  {project.next && (
                    <Link to={`/project/${project.next.slug}`} title={project.next.title}>
                      <span className="show-for-medium-up">NEXT PROJECT</span>{' '}
                      <svg style={{width: 30, height: 30, top: 10}}>
                        <use xlinkHref="#fa-sprite--angle-right" />
                      </svg>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </BasePage>
    )
  }
}

ProjectDetails.displayName = 'ProjectDetails'

function mapStateToProps(state) {
  return {
    ...state.projects,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getProject}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails)
