import {takeEvery} from 'redux-saga'
import {call, put} from 'redux-saga/effects'

import InnovationsApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* innovationsFlow() {
  yield [takeEvery(Types.INNOVATIONS_LIST_GET, getInnovationsList)]
  yield [takeEvery(Types.INNOVATION_GET, getInnovation)]
}

function* getInnovationsList(action) {
  try {
    const {params} = action
    const result = yield call(InnovationsApi.getList, params)
    yield put(Actions.getInnovationsListSuccess(result.results))
  } catch (error) {
    yield put(Actions.getInnovationsListFailure(error))
  }
}

function* getInnovation(action) {
  try {
    const {slug, params} = action
    const result = yield call(InnovationsApi.getInnovation, slug, params)
    yield put(Actions.getInnovationSuccess(result))
  } catch (error) {
    yield put(Actions.getInnovationFailure(error))
  }
}
