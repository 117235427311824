import settings from '../../../config/settings'
import axios from '../api/axios'

export default class PositionsApi {
  static getPosition(slug, params) {
    return axios.get(`${settings.apiBaseURL}/positions/${slug}`, {params})
  }

  static getList(params) {
    return axios.get(`${settings.apiBaseURL}/positions`, {params})
  }
}
