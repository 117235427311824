import {withStyles} from '@material-ui/core'
import React from 'react'
import {Link} from 'react-router'
import Slider from 'react-slick'

import bodyDetailsLogo from '../../assets/testimonials/bodyDetailsLogo.png'
import fsrLogo from '../../assets/testimonials/fsrLogo.png'
import quoteMarkBodyDetails from '../../assets/testimonials/quoteMarkBodyDetails.svg'
import quoteMarkFSR from '../../assets/testimonials/quoteMarkFSR.svg'
import quoteMarkVisa from '../../assets/testimonials/quoteMarkVisa.svg'
import quoteMarkVuPulse from '../../assets/testimonials/quoteMarkVuPulse.svg'
import visaLogo from '../../assets/testimonials/visaLogo.png'
import vuPulseLogo from '../../assets/testimonials/vuPulseLogo.png'
import LazyImage from '../common/LazyImage'

const styles = (theme) => ({
  vuPulseLogo: {
    width: 150,
    margin: '0 auto',
    marginTop: '25px',
  },
  fsrLogo: {
    width: 100,
    margin: '0 auto',
    marginTop: '25px',
  },
  visaLogo: {
    width: 100,
    margin: '0 auto',
    marginTop: '35px',
  },
  bodyDetailsLogo: {
    width: 250,
    margin: '0 auto',
    marginTop: '25px',
    [theme.breakpoints.down('xs')]: {
      width: 150,
    },
  },
  imageLarge: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  viewButtonBodyDetails: {
    paddingTop: 6,
    color: 'white',
    textAlign: 'center',
    backgroundColor: '#76963C',
    height: 38,
    bottom: 0,
    marginTop: '-10px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-20px',
    },
  },
  viewButtonFSR: {
    paddingTop: 6,
    color: 'white',
    textAlign: 'center',
    backgroundColor: '#0198D1',
    height: 38,
    bottom: 0,
    marginTop: '-10px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-20px',
    },
  },
  viewButtonVuPulse: {
    paddingTop: 6,
    color: 'white',
    textAlign: 'center',
    backgroundColor: '#EA1F1F',
    height: 38,
    bottom: 0,
    marginTop: '-10px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-20px',
    },
  },
  viewButtonVisa: {
    paddingTop: 6,
    color: 'white',
    textAlign: 'center',
    backgroundColor: '#192061',
    height: 38,
    bottom: 0,
    marginTop: '-10px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-30px',
    },
  },
  text: {
    marginBottom: 25,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 35,
    },
    '& h5': {
      display: 'inline-block',
      color: '#28a2d1',
      fontSize: '40px',
      fontWeight: 'bold',
      textAlign: 'center',
      paddingTop: 50,
      textDecoration: 'none',
      paddingRight: 10,
      [theme.breakpoints.down('xs')]: {
        fontSize: '28px',
      },
    },
    '& h6': {
      display: 'inline-block',
      color: '#28a2d1',
      fontSize: '30px',
      textAlign: 'center',
      paddingTop: 20,
      textDecoration: 'none',
      [theme.breakpoints.down('xs')]: {
        fontSize: '23px',
      },
    },
  },
  anchor: {
    position: 'absolute',
    transform: 'translateY(-100px)',
  },
})

class Testimonials extends React.Component {
  render() {
    const {classes} = this.props

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: true,
      useCSS: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: false,
      rtl: false,
      accessibility: false,
      touchMove: false,
      pauseOnHover: false,
      pauseOnFocus: false,
      pauseOnDotsHover: false,
      responsive: [
        {
          breakpoint: 1366,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 812,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }

    return (
      <div>
        <span className={classes.anchor} id="testimonials" />
        <style>{cssstyle}</style>
        <div className="testimonialsOuterContainer">
          <div className={classes.text}>
            <h5>TESTIMONIALS</h5>
          </div>
          <div className="sliderContainer">
            <Slider {...settings}>
              <div className="testimonialContainer">
                <div className="box backgroundRectangle" />
                <div className="box testimonial">
                  <Link to="/portfolio" className="grid-container">
                    <div className="grid-child imageClaudioSorrentino" />
                    <div className="grid-child text">
                      <div className="logoContainerBodyDetails">
                        <LazyImage
                          src={bodyDetailsLogo}
                          alt="Body Details Logo"
                          className={classes.bodyDetailsLogo}
                        />
                      </div>
                      <div className="testimonialText">
                        <div>
                          <LazyImage
                            src={quoteMarkBodyDetails}
                            alt="Quote Mark"
                            className="quoteMark"
                          />
                          <p className="testimonialParagraph">
                            I highly recommend The SilverLogic. They've become an extension of our
                            own team. They have a deep understanding of process automation and the
                            technologies necessary to bring it to life, which they did for us.{' '}
                            <br />
                            <br />
                            <p className="testimonialName">Claudio Sorrentino</p>
                            CEO
                          </p>
                        </div>
                      </div>
                      <div className={classes.viewButtonBodyDetails}>VIEW BODY DETAILS →</div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="testimonialContainer">
                <div className="box backgroundRectangle" />
                <div className="box testimonial">
                  <Link to="/innovations/realdeal" className="grid-container">
                    <div className="grid-child imageVanesaMeyer" />
                    <div className="grid-child text">
                      <div className="logoContainerVisa">
                        <LazyImage src={visaLogo} alt="Visa Logo" className={classes.visaLogo} />
                      </div>
                      <div className="testimonialText">
                        <div>
                          <LazyImage src={quoteMarkVisa} alt="Quote Mark" className="quoteMark" />
                          <p className="testimonialParagraph">
                            The SilverLogic delivers innovative solutions using cutting-edge
                            technologies. Thoroughness, quality, and value all executed in extremely
                            short delivery cycles. <br />
                            <br />
                            <p className="testimonialName">Vanesa Meyer</p>
                            Head of Innovation & Strategic Partnership
                          </p>
                        </div>
                      </div>
                      <div className={classes.viewButtonVisa}>VIEW VISA →</div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="testimonialContainer">
                <div className="box backgroundRectangle" />
                <div className="box testimonial">
                  <Link to="/portfolio" className="grid-container">
                    <div className="grid-child imageMichaelBodner" />
                    <div className="grid-child text">
                      <div className="logoContainerFSR">
                        <LazyImage src={fsrLogo} alt="FSR Logo" className={classes.fsrLogo} />
                      </div>
                      <div className="testimonialText">
                        <div>
                          <LazyImage src={quoteMarkFSR} alt="Quote Mark" className="quoteMark" />
                          <p className="testimonialParagraph">
                            The SilverLogic re-developed our app from the ground up, to make it a
                            phenomenal experience and a great product. They are professional,
                            courteous, and really quick [...]. They become an invested part in your
                            team and deliver great solutions. <br />
                            <br />
                            <p className="testimonialName">Michael Bodner</p>
                            Director, National Property Operations
                          </p>
                        </div>
                      </div>
                      <div className={classes.viewButtonFSR}>VIEW FIRST SERVICE RESIDENTIAL →</div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="testimonialContainer">
                <div className="box backgroundRectangle" />
                <div className="box testimonial">
                  <Link to="/project/vupulse" className="grid-container">
                    <div className="grid-child imageKevin" />
                    <div className="grid-child text">
                      <div className="logoContainerVuPulse">
                        <LazyImage
                          src={vuPulseLogo}
                          alt="Vu Pulse Logo"
                          className={classes.vuPulseLogo}
                        />
                      </div>
                      <div className="testimonialText">
                        <div>
                          <LazyImage
                            src={quoteMarkVuPulse}
                            alt="Quote Mark"
                            className="quoteMark"
                          />
                          <p className="testimonialParagraph">
                            TSL works with you as a team and not a contractor. Most importantly they
                            deliver thought leadership. They help you think through your problem and
                            come up with creative solutions when you’re trying to develop your new
                            idea. <br />
                            <br />
                            <p className="testimonialName">Kevin Hill</p>
                            Co-Founder & CEO
                          </p>
                        </div>
                      </div>
                      <div className={classes.viewButtonVuPulse}>VIEW VUPULSE →</div>
                    </div>
                  </Link>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    )
  }
}
const cssstyle = `
.slick-slide, .slick-slide *{ outline: none !important; };
`

Testimonials.displayName = 'Testimonials'

export default withStyles(styles)(Testimonials)
