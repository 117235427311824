import PropTypes from 'prop-types'
import React from 'react'

const AspectRatio = (props) => {
  const ratio = `${(1 / props.ratio) * 100}%`
  return (
    <div style={{position: 'relative', width: '100%'}}>
      <div style={{paddingBottom: ratio}}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default AspectRatio

AspectRatio.propTypes = {
  ratio: PropTypes.number.isRequired,
}
