import * as Types from './actions.types'

export function getTagsList(params = {}) {
  return {type: Types.TAGS_LIST_GET, params}
}

export function getTagsListSuccess(tags) {
  return {type: Types.TAGS_LIST_GET_SUCCESS, tags}
}

export function getTagsListFailure(error) {
  return {type: Types.TAGS_LIST_GET_FAILURE, error}
}
