import settings from '../../../config/settings'
import axios from '../api/axios'

export default class HomeApi {
  static getHomeByName(name) {
    return axios.get(`${settings.apiBaseURL}/homes/${name}?expand=ctaform`)
  }

  static getHomes() {
    return axios.get(`${settings.apiBaseURL}/homes`)
  }

  static getProjectsCarousel() {
    return axios.get(`${settings.apiBaseURL}/projects/homepage-carousel`)
  }

  static getProjectsCarouselDiscover() {
    return axios.get(`${settings.apiBaseURL}/projects/homepage-carousel-discover`)
  }
}
