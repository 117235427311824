import * as Types from './actions.types'

export function getInnovationsList(params = {}) {
  return {type: Types.INNOVATIONS_LIST_GET, params}
}

export function getInnovationsListSuccess(innovations) {
  return {type: Types.INNOVATIONS_LIST_GET_SUCCESS, innovations}
}

export function getInnovationsListFailure(error) {
  return {type: Types.INNOVATIONS_LIST_GET_FAILURE, error}
}

export function getInnovation(slug, params = {}) {
  return {type: Types.INNOVATION_GET, slug, params}
}

export function getInnovationSuccess(innovation) {
  return {type: Types.INNOVATION_GET_SUCCESS, innovation}
}

export function getInnovationFailure(error) {
  return {type: Types.INNOVATION_GET_FAILURE, error}
}
