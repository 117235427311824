import {takeEvery} from 'redux-saga'
import {call, put} from 'redux-saga/effects'

import Api from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* feedFlow() {
  yield [takeEvery(Types.TEAM_MEMBERS_GET, getSocialFeed)]
}

function* getSocialFeed() {
  try {
    const result = yield call(Api.getTeamMembers)
    yield put(Actions.getTeamMembersSuccess(result.results))
  } catch (error) {
    yield put(Actions.getTeamMembersFailure(error))
  }
}
