import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {Link} from 'react-router'
import classNames from 'classnames'
import Loading from '../common/Loading.jsx'
import {getCaseStudiesList} from './redux/actions'
import {retinaOrNormalSrc} from '../utils/ImageUtils.js'

class CaseStudyItem extends React.Component {
  render() {
    const {caseStudy} = this.props

    return (
      <Link
        to={`/case-study/${caseStudy.slug}`}
        className={classNames('case_studiesPage__list__item')}
        style={
          caseStudy.backgroundImage && {
            backgroundImage: `url(${retinaOrNormalSrc(caseStudy.backgroundImage)})`,
          }
        }
      >
        <strong>{caseStudy.title}</strong>
      </Link>
    )
  }
}

class FeaturedCaseStudies extends React.Component {
  componentWillMount() {
    this.props.getCaseStudiesList({is_featured: 1})
  }

  render() {
    const {caseStudies} = this.props

    return (
      <div className={classNames('case_studies')}>
        <div className="row container">
          <div className={classNames('case_studiesPage__list clearfix')}>
            {caseStudies.isFetching ? (
              <Loading />
            ) : (
              caseStudies.list.map((caseStudy) => {
                return (
                  <div className="center-feature" key={caseStudy.id}>
                    <CaseStudyItem caseStudy={caseStudy} />
                  </div>
                )
              })
            )}
          </div>
        </div>
      </div>
    )
  }
}

FeaturedCaseStudies.displayName = 'FeaturedCaseStudies'

function mapStateToProps(state) {
  return {
    caseStudies: state.caseStudies,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getCaseStudiesList}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedCaseStudies)
