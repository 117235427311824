import classNames from 'classnames'
import React, {Component} from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {Link} from 'react-router'
import {bindActionCreators} from 'redux'

import settings from '../../../config/settings'
import FeaturedCaseStudies from '../case_studies/FeaturedCaseStudy.jsx'
import BasePageNew from '../common/BasePageNew.jsx'
import LazyImage from '../common/LazyImage'
import {getInnovationsList} from '../innovations/redux/actions'
import {getPage} from '../pages/redux/actions'
import ProjectsCTAForm from '../projects/ProjectsCTAForm.jsx'
import {getProjectsList} from '../projects/redux/actions'
import {retinaOrNormalSrc} from '../utils/ImageUtils.js'
import ClientBarOurWork from './ClientBarOurWork.jsx'

class PortfolioItem extends Component {
  // state = {showDescription: false}

  // showDescription = () => {
  //   this.setState({showDescription: true})
  // }

  // hideDescription = () => {
  //   this.setState({showDescription: false})
  // }

  render() {
    const {item, link} = this.props
    return (
      <React.Fragment>
        {item.whatWeDid ? (
          <div
            // onMouseEnter={this.showDescription}
            // onMouseLeave={this.hideDescription}
            className={classNames('portfolio-item large-4 columns end')}
          >
            <Helmet>
              <link rel="canonical" href={`${settings.appBaseURL}/portfolio`} />
              <meta property="og:url" href={`${settings.appBaseURL}/portfolio`} />
            </Helmet>
            <div className="portfolio-item__contentContainer large-4 columns">
              <Link to={link} key={item.id}>
                <div className="portfolio-item__mainContent">
                  <h3 className="gridHeader">{item.title}</h3>
                  <div className="portfolio-item__mainContent__span">
                    <span>{item.tagline}</span>
                  </div>
                  <p>{item.description}</p>
                </div>
                <div className="portfolio-item__imageContainer">
                  <LazyImage src={retinaOrNormalSrc(item.mockup)} alt={`${item.title} mockup`} />
                </div>

                <div className="portfolio-item__readMore">
                  <Link to={link} key={item.id} className="readMoreButton">
                    Read more
                  </Link>
                </div>
              </Link>
            </div>
          </div>
        ) : (
          <div className={classNames('portfolio-item large-4 columns end')}>
            <Helmet>
              <link rel="canonical" href={`${settings.appBaseURL}/portfolio`} />
              <meta property="og:url" href={`${settings.appBaseURL}/portfolio`} />
            </Helmet>
            <div className="portfolio-item__contentContainer large-4 columns">
              <div className="portfolio-item__mainContent">
                <h3 className="gridHeader">{item.title}</h3>
                <div className="portfolio-item__mainContent__span">
                  <span>{item.tagline}</span>
                </div>
                <p>{item.description}</p>
              </div>
              <div className="portfolio-item__imageContainer">
                <LazyImage src={retinaOrNormalSrc(item.mockup)} alt={`${item.title} mockup`} />
              </div>
              <div>
                <div>
                  <p>&nbsp;</p>
                  <p style={{paddingBottom: 14}}>&nbsp;</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

class Portfolio extends Component {
  componentWillMount() {
    this.props.getPage('portfolio')
    this.props.getInnovationsList({is_portfolio: 1})
    this.props.getProjectsList({is_portfolio: 1})
  }

  render() {
    const {page, isFetching, notFound, projects, innovations} = this.props

    const projectsComps = (projects.list || []).map((project) => {
      return <PortfolioItem key={project.pk} item={project} link={`/project/${project.slug}`} />
    })

    const innovationsComps = (innovations.list || []).map((innovation) => {
      return (
        <PortfolioItem
          key={innovation.pk}
          item={innovation}
          link={`/innovations/${innovation.slug}`}
        />
      )
    })

    return (
      <BasePageNew className="portfolio" page={page} isFetching={isFetching} notFound={notFound}>
        <ClientBarOurWork />
        {page && (
          <div style={{backgroundColor: '#f1f1f1'}}>
            <div className="row container">
              <div
                className="medium-12 large-12  columns items"
                style={{
                  flexWrap: 'wrap',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  marginTop: '60px',
                }}
              >
                <span className="anchor" id="projects" />
                <h2 style={{width: '100%'}}>PROJECTS</h2>
                {projectsComps}
                {innovationsComps}
              </div>
            </div>
            <div>
              <h2 className="caseStudies">CASE STUDIES</h2>
              <FeaturedCaseStudies />
            </div>
            <div className="cta-container">
              {page?.ctaform && <ProjectsCTAForm ctaform={(page || {}).ctaform} />}
            </div>
          </div>
        )}
      </BasePageNew>
    )
  }
}

Portfolio.displayName = 'Portfolio'

function mapStateToProps(state) {
  return {...state.pages, innovations: state.innovations, projects: state.projects}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getPage, getInnovationsList, getProjectsList}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio)
