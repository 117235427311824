import settings from '../../../config/settings'
import axios from '../api/axios'

export default class CaseStudiesApi {
  static getCaseStudy(slug, params) {
    return axios.get(`${settings.apiBaseURL}/case-studies/${slug}`, {params})
  }

  static getList(params) {
    return axios.get(`${settings.apiBaseURL}/case-studies`, {params})
  }
}
