import * as Types from './actions.types'

export function getSkillsList(params = {}) {
  return {type: Types.SKILLS_LIST_GET, params}
}

export function getSkillsListSuccess(skills) {
  return {type: Types.SKILLS_LIST_GET_SUCCESS, skills}
}

export function getSkillsListFailure(error) {
  return {type: Types.SKILLS_LIST_GET_FAILURE, error}
}

export function getSkill(slug) {
  return {type: Types.SKILL_GET, slug}
}

export function getSkillSuccess(skill) {
  return {type: Types.SKILL_GET_SUCCESS, skill}
}

export function getSkillFailure(error) {
  return {type: Types.SKILL_GET_FAILURE, error}
}
