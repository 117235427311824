import classNames from 'classnames'
import React from 'react'
import Helmet from 'react-helmet'

import {retinaOrNormalSrc} from '../utils/ImageUtils.js'
import Loading from './Loading.jsx'
import NotFound from './NotFound.Page.jsx'

class BasePage extends React.Component {
  render() {
    const {page, isFetching, notFound, className, customHeader} = this.props
    const hasCustomHeader = typeof customHeader !== 'undefined'
    if (notFound) return <NotFound />
    if (!page) return <Loading />

    return (
      <div className={classNames('clearfix pageDetails', className)}>
        <Helmet title={page.metaTitle}>
          <meta property="og:title" content={page.metaTitle} />
          {page.metaDescription && <meta name="description" content={page.metaDescription} />}
          {page.metaDescription && (
            <meta property="og:description" content={page.metaDescription} />
          )}
          {page.ogImage && <meta property="og:image" content={page.ogImage.fullSize} />}
        </Helmet>
        {hasCustomHeader ? (
          customHeader
        ) : (
          <div
            className="pageDetails__header"
            style={
              page.headerImage && {backgroundImage: `url(${retinaOrNormalSrc(page.headerImage)})`}
            }
          >
            <div className="row container">
              <h1>{page.title}</h1>
            </div>
          </div>
        )}
        {isFetching && (
          <div className="pageDetails__loading">
            <Loading />
          </div>
        )}
        {this.props.children}
      </div>
    )
  }
}

BasePage.displayName = 'BasePage'

export default BasePage
