import get from 'lodash/get'
import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import ReactSwipe from 'react-swipe'
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs'
import {bindActionCreators} from 'redux'

import settings from '../../../config/settings'
import BasePage from '../common/BasePage.jsx'
import {getSocialFeed} from '../feed/redux/actions'
import SocialFeed from '../feed/SocialFeed.jsx'
import {getTeamMembers} from '../team_members/redux/actions'
import TeamMembersGrid from '../team_members/TeamMembersGrid.jsx'
import AboutUsCTAForm from './AboutUsCTAForm.jsx'
import {getPage} from './redux/actions'

class AboutUs extends React.Component {
  state = {currentPos: 0}

  componentWillMount() {
    this.props.getPage('about-us')
    this.props.getTeamMembers()
    this.props.getSocialFeed()
  }

  render() {
    const {page, isFetching, notFound, teamMembers, feed} = this.props
    return (
      <BasePage className="aboutUs" page={page} isFetching={isFetching} notFound={notFound}>
        <Helmet>
          <link rel="canonical" href={`${settings.appBaseURL}/about-us`} />
          <meta property="og:url" href={`${settings.appBaseURL}/about-us`} />
        </Helmet>
        {page && (
          <div>
            <div className="row container">
              <div className="pageDetails__intro">
                <h2>Our Mission</h2>
                <p>{page.body}</p>
              </div>
            </div>

            <TeamMembersGrid members={teamMembers.list} />

            <div className="row container">
              <div className="clearfix pageDetails__intro aboutUs__ourProcess">
                <h2>Our Process</h2>
                <Tabs className="show-for-medium-up">
                  <TabList>
                    <Tab>Deconstruct</Tab>
                    <Tab>Discover</Tab>
                    <Tab>Deliver</Tab>
                  </TabList>

                  <TabPanel
                    dangerouslySetInnerHTML={{__html: get(page, 'sections.deconstruct.body')}}
                  />
                  <TabPanel
                    dangerouslySetInnerHTML={{__html: get(page, 'sections.discover.body')}}
                  />
                  <TabPanel
                    dangerouslySetInnerHTML={{__html: get(page, 'sections.deliver.body')}}
                  />
                </Tabs>
                <ReactSwipe
                  className="show-for-small-only"
                  swipeOptions={{
                    callback: (index) => {
                      this.setState({currentPos: index})
                    },
                  }}
                >
                  <div key={1}>
                    <div className="aboutUs-mobile-title">
                      <span className="techs_mobile_title">
                        {get(page, 'sections.deconstruct.title')}
                      </span>
                    </div>
                    <TabPanel
                      className="react-tabs__tab-panel react-tabs__tab-panel--mobile"
                      key={get(page, 'sections.deconstruct.key')}
                      dangerouslySetInnerHTML={{__html: get(page, 'sections.deconstruct.body')}}
                    />
                  </div>
                  <div key={2}>
                    <div className="aboutUs-mobile-title">
                      <span className="techs_mobile_title">
                        {get(page, 'sections.discover.title')}
                      </span>
                    </div>
                    <TabPanel
                      className="react-tabs__tab-panel react-tabs__tab-panel--mobile"
                      key={get(page, 'sections.discover.key')}
                      dangerouslySetInnerHTML={{__html: get(page, 'sections.discover.body')}}
                    />
                  </div>
                  <div key={3}>
                    <div className="aboutUs-mobile-title">
                      <span className="techs_mobile_title">
                        {get(page, 'sections.deliver.title')}
                      </span>
                    </div>
                    <TabPanel
                      className="react-tabs__tab-panel react-tabs__tab-panel--mobile"
                      key={get(page, 'sections.deliver.key')}
                      dangerouslySetInnerHTML={{__html: get(page, 'sections.deliver.body')}}
                    />
                  </div>
                </ReactSwipe>
              </div>
              <div className="show-for-small-only aboutus_dots">{this.renderDots()}</div>
            </div>
            <SocialFeed feed={feed.list} />
            <div className="skillDetails__CTA_bottom_padding">
              {page.ctaform && !isFetching && <AboutUsCTAForm ctaform={page.ctaform} />}
            </div>
          </div>
        )}
      </BasePage>
    )
  }

  renderDots() {
    const totalAmountOfSlides = 3
    const dots = []
    const style = {
      width: 16,
      height: 16,
      marginRight: 10,
    }
    for (let i = 0; i < totalAmountOfSlides; i++) {
      if (i === this.state.currentPos) {
        dots.push(
          <svg style={style}>
            <use xlinkHref="#fa-sprite--circle" />
          </svg>,
        )
      } else {
        dots.push(
          <svg style={style}>
            <use xlinkHref="#fa-sprite--circle-o" />
          </svg>,
        )
      }
    }
    return <div>{dots}</div>
  }
}

AboutUs.displayName = 'AboutUs'

function mapStateToProps(state) {
  return {...state.pages, teamMembers: state.teamMembers, feed: state.feed}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getPage, getTeamMembers, getSocialFeed}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs)
