import * as Types from './actions.types'

export function getSocialFeed() {
  return {type: Types.SOCIAL_FEED_GET}
}

export function getSocialFeedSuccess(feed) {
  return {type: Types.SOCIAL_FEED_GET_SUCCESS, feed}
}

export function getSocialFeedFailure(error) {
  return {type: Types.SOCIAL_FEED_GET_FAILURE, error}
}

export function getRecentBlogPostsByTopic(topicId) {
  return {type: Types.BLOG_POSTS_GET_BY_TOPIC, topicId}
}

export function getRecentBlogPostsSuccess(blogPosts) {
  return {type: Types.BLOG_POSTS_GET_SUCCESS, blogPosts}
}

export function getRecentBlogPostsFailure(error) {
  return {type: Types.BLOG_POSTS_GET_FAILURE, error}
}
