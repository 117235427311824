import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import {withStyles} from '@material-ui/core/styles'
import React from 'react'

import LampIcon from '../../assets/home-page/lamp.png'

const DiscoverButton = ({classes}) => (
  <Button variant="contained" color="primary" className={classes.button}>
    <Icon className={classes.leftIcon}>
      <img src={LampIcon} alt="lamp" />
    </Icon>
    Discover your idea
  </Button>
)

const styles = (theme) => ({
  button: {
    color: '#25262E',
    width: 'auto',
    height: '46px',
    border: '1px solid #25262E',
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: 600,
    backgroundColor: 'transparent',
    borderRadius: '40px',
    marginLeft: '64px',
    marginTop: '32px',
    boxShadow: 'none',
    display: 'flex',
    whiteSpace: 'nowrap',
    padding: '8px 16px 8px 8px',
    transition: `all 0.4s ease-out`,
    [theme.breakpoints.down('sm')]: {
      margin: '24px 96px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '16px 16px',
    },
    '&:hover': {
      border: '1px solid #009CD8',
      backgroundColor: '#009CD8',
      color: '#FFFFFF',
      transition: `all 0.4s ease-out`,
    },
    '&:active': {
      border: '1px solid #009CD8',
      backgroundColor: '#009CD8',
      color: '#FFFFFF',
      transition: `all 0.4s ease-out`,
    },
  },
  leftIcon: {
    marginRight: '8px',
    backgroundColor: '#009CD8',
    borderRadius: '40px',
    height: `30px`,
    width: `30px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px',
  },
})

export default withStyles(styles)(DiscoverButton)
