import {takeEvery} from 'redux-saga'
import {call, put} from 'redux-saga/effects'

import TechsApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'

export default function* techsFlow() {
  yield [takeEvery(Types.TECHS_LIST_GET, getTechsList)]
}

function* getTechsList(action) {
  try {
    const {params} = action
    const result = yield call(TechsApi.getList, params)
    yield put(Actions.getTechsListSuccess(result.results))
  } catch (error) {
    yield put(Actions.getTechsListFailure(error))
  }
}
