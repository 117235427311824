import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import settings from '../../../config/settings'
import BasePage from '../common/BasePage.jsx'
import Loading from '../common/Loading.jsx'
import {getPage} from '../pages/redux/actions'
import ProjectItem from './ProjectItem.jsx'
import ProjectsCTAForm from './ProjectsCTAForm.jsx'
import {getProjectsList} from './redux/actions'

class Projects extends React.Component {
  componentWillMount() {
    this.props.getPage('projects')
    this.props.getProjectsList()
  }

  render() {
    const {page, projects, isFetching, notFound} = this.props

    return (
      <BasePage className="projects" page={page} isFetching={isFetching} notFound={notFound}>
        <Helmet>
          <link rel="canonical" href={`${settings.appBaseURL}/projects`} />
          <meta property="og:url" href={`${settings.appBaseURL}/projects`} />
        </Helmet>
        {page && (
          <div className="projectsPage">
            <div className="projectsPage__row container">
              <div className="projectsPage__list">
                {projects.isFetching ? (
                  <Loading />
                ) : projects.list.size >= 6 ? (
                  projects.list.map((project, i) => {
                    const ctaform = i === 2 && (
                      <ProjectsCTAForm class="row" ctaform={page.ctaform} />
                    )
                    return (
                      <div key={i}>
                        {ctaform}
                        <ProjectItem project={project} />
                      </div>
                    )
                  })
                ) : (
                  projects.list.map((project, i) => {
                    return (
                      <div key={i}>
                        <ProjectItem project={project} />
                      </div>
                    )
                  })
                )}
              </div>
              <div className="skillDetails__CTA_bottom_padding">
                <ProjectsCTAForm ctaform={page.ctaform} />
              </div>
            </div>
          </div>
        )}
      </BasePage>
    )
  }
}

Projects.displayName = 'Projects'

function mapStateToProps(state) {
  return {
    ...state.pages,
    projects: state.projects,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getProjectsList, getPage}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
