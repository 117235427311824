import PropTypes from 'prop-types'
import React from 'react'

const callbacks = []

function addScript(src, cb) {
  if (callbacks.length === 0) {
    callbacks.push(cb)
    const s = document.createElement('script')
    s.setAttribute('src', src)
    s.onload = () => callbacks.forEach((cb) => cb())
    document.body.appendChild(s)
  } else {
    callbacks.push(cb)
  }
}

class TwitterVideo extends React.Component {
  componentDidMount() {
    const renderTweet = () => {
      window.twttr.ready().then(({widgets}) => {
        const {id, options} = this.props
        widgets
          .createVideo(id, this._div, {options})
          .then(() => this.onVideoLoaded())
          .catch(() => this.onVideoError())
      })
    }

    if (!window.twttr) {
      const isLocal = window.location.protocol.indexOf('file') >= 0
      const protocol = isLocal ? this.props.protocol : ''

      addScript(`${protocol}//platform.twitter.com/widgets.js`, renderTweet)
    } else {
      renderTweet()
    }
  }

  onVideoLoaded() {
    const {onVideoLoadSuccess} = this.props
    if (onVideoLoadSuccess) {
      onVideoLoadSuccess()
    }

    // Remove the margins from the body
    if (this._div) {
      const iframes = this._div.getElementsByTagName('iframe')
      if (iframes.length <= 0) {
        return
      }

      const {body} = iframes[0].contentWindow.document
      body.style.margin = '0px'
    }
  }

  onVideoError() {
    const {onVideoLoadError} = this.props
    if (onVideoLoadError) {
      onVideoLoadError()
    }
  }

  render() {
    return (
      <div
        className={this.props.className}
        ref={(c) => {
          this._div = c
        }}
      />
    )
  }
}

TwitterVideo.propTypes = {
  id: PropTypes.string,
  options: PropTypes.object,
  protocol: PropTypes.string,
  onVideoLoadSuccess: PropTypes.func,
  onVideoLoadError: PropTypes.func,
  className: PropTypes.string,
}

TwitterVideo.defaultProps = {
  protocol: 'https:',
  options: {},
  className: null,
}

export default TwitterVideo
