import * as Types from './actions.types'

function createInitialState() {
  return {
    home: {},
    isFetching: false,
    notFound: false,
  }
}

export default function home(state = createInitialState(), action) {
  switch (action.type) {
    case Types.HOME_GET:
      return {
        ...createInitialState(),
        home: {},
        isFetching: true,
      }
    case Types.HOME_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        notFound: false,
        home: action.home,
      }
    case Types.HOME_GET_FAILURE:
      return {
        ...state,
        home: {},
        isFetching: false,
        notFound: true,
      }
    default:
      return state
  }
}
