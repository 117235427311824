import {withStyles} from '@material-ui/core'
import React from 'react'

import missionBackground from '../../assets/missionBackground.png'

const styles = (theme) => ({
  container: {
    backgroundImage: `url(${missionBackground})`,
    height: '500px',
    backgroundSize: 'cover',
    marginLeft: '-2px',
  },
  text: {
    textAlign: 'center',
    width: '50%',
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
  },
  header: {
    color: 'white !important',
    fontSize: '50px',
    lineHeight: '65px',
    paddingTop: '5px',
    textTransform: 'capitalize !important',
    background: '-webkit-linear-gradient(left, #58f1ff , #009cd8) !important',
    '-webkit-background-clip': 'text !important',
    '-webkit-text-fill-color': 'transparent !important',
    letterSpacing: '.1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
      lineHeight: '30px',
      marginBottom: '15px',
    },
  },
  body: {
    color: 'white !important',
    fontSize: '20px !important',
    lineHeight: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '17px',
      lineHeight: '30px',
    },
  },
})

class MissionStatement extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div>
        <div className={classes.container}>
          <div className={classes.text}>
            <h2 className={classes.header}>
              Client-focused, Value-driven, Custom Software Development
            </h2>
            <p className={classes.body}>
              Working closely with you, The SilverLogic will provide the innovation and automation
              needed to identify and eliminate operational inefficiencies, deliver scalable growth,
              and develop custom technology solutions that drive measurable business outcomes.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

MissionStatement.displayName = 'MissionStatement'

export default withStyles(styles)(MissionStatement)
