import React from 'react'

const TeamMemberCell = (props) => {
  const {member} = props
  let className = 'team-member-cell'
  if (member.isFeatured) {
    className += ' featured'
  }

  return (
    <div className={className}>
      <img src={member.headshot.fullSize} alt={member.name} />
      <h2>{member.name.toUpperCase()}</h2>
      <p>{member.title}</p>
    </div>
  )
}

export default TeamMemberCell
