import {Grid, withStyles} from '@material-ui/core'
import React from 'react'
import {Link} from 'react-router'

import Accounting from '../../assets/whoWeServe/accountingIcon.png'
import BeautyWellness from '../../assets/whoWeServe/beautyWellnessIcon.png'
import Brokering from '../../assets/whoWeServe/brokeringIcon.png'
import Construction from '../../assets/whoWeServe/constructionIcon.png'
import Financial from '../../assets/whoWeServe/financialIcon.png'
import Healthcare from '../../assets/whoWeServe/healthcareIcon.png'
import WhoWeServe from '../../assets/whoWeServe/whoWeServeBackground.jpg'
import LazyImage from '../common/LazyImage'

const styles = (theme) => ({
  container: {
    margin: '0 auto',
    height: 800,
    paddingRight: '9%',
    paddingLeft: '9%',
    paddingTop: '5%',
    verticalAlign: 'middle',
    backgroundImage: `url(${WhoWeServe})`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 1100,
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: '8%',
      paddingLeft: '8%',
    },
  },
  columnOne: {
    width: '90%',
    textAlign: 'center',
    margin: '0 auto',
  },
  headerColumn: {
    width: '100%',
    textAlign: 'center',
  },
  subTextColumn: {
    width: '100%',
    textAlign: 'center',
  },

  headerText: {
    color: 'white',
    fontSize: '42px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 20,
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      color: 'white !important',
      fontSize: '30px !important',
    },
  },

  button: {
    textAlign: 'center',
  },
})

class SeenOn extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <Grid
        container
        className={classes.container}
        direction="row"
        justify="center"
        alignItems="baseline"
        height="auto"
      >
        <Grid item xs={12}>
          <div className={classes.headerColumn}>
            <h5 className={classes.headerText}>WHO WE SERVE</h5>
          </div>
          <div className={classes.subTextColumn}>
            <p className="subText">
              Most businesses share similar challenges when it comes to processes and automation,
              allowing us to serve countless industries while maintaining deep domain knowledge in
              the most popular ones.
            </p>
          </div>
          <Link
            to="https://go.tsl.io/meetings/cj9/meeting-requested-on-site"
            className={classes.button}
            target="_blank"
          >
            <p className="buttonText">Schedule a Free Consultation →</p>
          </Link>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <div className={classes.columnOne}>
            <LazyImage className="iconHealth" src={Healthcare} alt="healthcare icon" />
            <p className="iconText1">HEALTHCARE</p>
            <Link to="/project/Hdrum" rel="noopener noreferrer">
              <p className="iconButton">Read More →</p>
            </Link>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <div className={classes.columnOne}>
            <LazyImage className="iconBrokering" src={Brokering} alt="brokering icon" />
            <p className="iconText1">BROKERING </p>
            <Link to="/project/GTS" rel="noopener noreferrer">
              <p className="iconButton">Read More →</p>
            </Link>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <div className={classes.columnOne}>
            <LazyImage className="iconConstruction" src={Construction} alt="construction icon" />
            <p className="iconText">CONSTRUCTION </p>
            <Link to="/project/FLULT" rel="noopener noreferrer">
              <p className="iconButton">Read More →</p>
            </Link>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <div className={classes.columnOne}>
            <LazyImage
              className="iconBeautyWellness"
              src={BeautyWellness}
              alt="beauty and wellness icon"
            />
            <p className="iconText1">BEAUTY & WELLNESS </p>
            <Link to="/portfolio" rel="noopener noreferrer">
              <p className="iconButton">Read More →</p>
            </Link>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <div className={classes.columnOne}>
            <LazyImage className="iconAccounting" src={Accounting} alt="accounting icon" />
            <p className="iconText">ACCOUNTING </p>
            <Link to="/portfolio" rel="noopener noreferrer">
              <p className="iconButton">Read More →</p>
            </Link>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <div className={classes.columnOne}>
            <LazyImage className="iconFinancial" src={Financial} alt="financial icon" />
            <p className="iconText">FINANCIAL </p>
            <Link to="/innovations/realdeal" rel="noopener noreferrer">
              <p className="iconButton">Read More →</p>
            </Link>
          </div>
        </Grid>
      </Grid>
    )
  }
}

SeenOn.displayName = 'SeenOn'

export default withStyles(styles)(SeenOn)
