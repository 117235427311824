import React from 'react'
import Masonry from 'react-masonry-component'

import SocialFeedCell from './SocialFeedCell.jsx'

const SocialFeed = (props) => {
  const {feed} = props
  if (!feed) {
    return null
  }
  if (feed.length === 0) {
    return null
  }

  const cells = feed.map((post, i) => {
    return (
      <div key={i} className="social-grid-item">
        <SocialFeedCell post={post} />
      </div>
    )
  })

  const socialIconStyle = {
    width: 26,
    height: 26,
    marginLeft: 10,
  }

  return (
    <div className="social-container">
      <p
        className="ba-social-header"
        style={{
          color: '#28a2d1',
          fontSize: '30',
          height: '5',
          fontWeight: '600',
        }}
      >
        FOLLOW US
      </p>
      <p
        style={{
          textAlign: 'center',
          paddingRight: '35',
          paddingBottom: '10',
          height: '40',
        }}
      >
        <a
          href="https://www.facebook.com/TheSilverLogic/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg style={socialIconStyle}>
            <use xlinkHref="#fa-sprite--facebook-square" />
          </svg>
        </a>
        <a
          href="https://www.linkedin.com/company/silverlogic-llc"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg style={socialIconStyle}>
            <use xlinkHref="#fa-sprite--linkedin-square" />
          </svg>
        </a>
        <a href="https://twitter.com/thesilverlogic" target="_blank" rel="noopener noreferrer">
          <svg style={socialIconStyle}>
            <use xlinkHref="#fa-sprite--twitter-square" />
          </svg>
        </a>
      </p>
      <Masonry options={{gutter: 30}} className="social-grid">
        {cells}
      </Masonry>
    </div>
  )
}

export default SocialFeed
