import {Hidden} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {Carousel} from 'react-responsive-carousel'
import {Link} from 'react-router'
import {bindActionCreators, compose} from 'redux'

import settings from '../../../config/settings'
import background from '../../assets/Background.png'
import Arrow from '../../assets/home-page/Arrow.svg'
import Line from '../../assets/home-page/Line.svg'
import PeopleIcon from '../../assets/home-page/PeopleIcon.svg'
import StuffIcon from '../../assets/home-page/StuffIcon.svg'
import RecentProjects from '../projects/RecentProjects.jsx'
import {getSkillsList} from '../skills/redux/actions'
import SkillsList from '../skills/SkillsList.jsx'
import HomeApi from './api'
import Awards from './Awards.jsx'
import ClientBar from './ClientBar.jsx'
import CTAForm from './CTAForm.jsx'
import DiscoverButton from './DiscoverButton.jsx'
import MissionStatement from './MissionStatement.jsx'
import Products from './Products.jsx'
import {getHome} from './redux/actions'
import SeenOn from './SeenOn.jsx'
import Testimonials from './Testimonials.jsx'
import WhoWeServe from './WhoWeServe.jsx'

const styles = (theme) => ({
  background: {
    backgroundImage: `linear-gradient(180deg, rgba(245, 249, 252, 0) 0%, #F2F2F2 100%), url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 88,
    minHeight: '75vh',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      justifyContent: 'space-evenly',
      minHeight: 0,
    },
  },
  grid: {
    marginTop: 50,
    display: 'grid',
    gridTemplateColumns: '60% 40%',
    width: '100%',
    maxWidth: '1150px',
    gridTemplateAreas: '"text carousel"',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      gridTemplateAreas: '"text"',
      marginTop: 64,
    },
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    gridArea: 'text',
    height: '100%',
    alignItems: 'flex-start',
    marginTop: 46,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  right: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gridArea: 'carousel',
    zIndex: 2,
  },
  text: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '70px',
    lineHeight: '100%',
    color: '#25262E',
    letterSpacing: '-1px',
    marginLeft: '64px',
    [theme.breakpoints.down('sm')]: {
      margin: '0px 96px',
      fontSize: '48px',
      lineHeight: '110%',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0px 16px',
      fontSize: '36px',
      lineHeight: '110%',
    },
  },
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  boxesContainer: {
    display: 'flex',
    marginTop: 128,
    marginLeft: 64,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 96,
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      marginLeft: 0,
      width: '100%',
    },
  },
  box: {
    borderRadius: 4,
    padding: 32,
    background: 'linear-gradient(180deg, #1C1B22 0%, #1C1B22 100%)',
    minWidth: 205,
    minHeight: 320,
    overflow: 'hidden',
    transition: 'all 0.4s cubic-bezier(0.7, 0, 0.3, 1)',
    '& + &': {
      marginLeft: 16,
    },
    '& > img.icon': {
      transition: 'all 0.4s cubic-bezier(0.7, 0, 0.3, 1)',
    },
    '& > img.line': {
      transition: 'all 0.4s cubic-bezier(0.7, 0, 0.3, 1)',
    },
    '& > img.arrow': {
      transition: 'all 0.4s cubic-bezier(0.7, 0, 0.3, 1)',
      marginLeft: '-9px',
    },
    '&:hover': {
      background: 'linear-gradient(180deg, #33C6FF 0%, #3381FF 100%)',
      cursor: 'pointer',
    },
    '&:hover > img.icon': {
      transform: 'scale(1.9) rotate(45deg) translateY(-5px) translateX(-5px)',
    },
    '&:hover > img.arrow': {
      transform: 'translateX(70px)',
    },
    '&:hover > img.line': {
      transform: 'scaleX(2)',
      transformOrigin: 'center left',
    },
    '&:active': {
      background: 'linear-gradient(180deg, #33C6FF 0%, #3381FF 100%)',
    },
    '&:active > img.icon': {
      transform: 'scale(1.9) rotate(45deg) translateY(-5px) translateX(-5px)',
    },
    '&:active > img.arrow': {
      transform: 'translateX(70px)',
    },
    '&:active > img.line': {
      transform: 'scaleX(2)',
      transformOrigin: 'center left',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 200,
      minHeight: 300,
      '& + &': {
        marginLeft: 8,
      },
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 175,
    },
  },
  boxText: {
    fontWeight: 700,
    color: '#fff',
    fontSize: 20,
    marginTop: 42,
  },
})

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      projects: [],
    }
  }

  componentWillMount() {
    const {getHome, getSkillsList} = this.props
    getHome()
    getSkillsList({is_featured: true})
    HomeApi.getProjectsCarousel().then(({results}) => {
      this.setState((prevState) => ({...prevState, projects: results}))
    })
  }

  render() {
    const {home, classes, skills} = this.props
    const {projects} = this.state

    return (
      <div className="ba-home">
        <Helmet>
          <meta property="og:url" href={`${settings.appBaseURL}/`} />
        </Helmet>
        {home.headerImage && (
          <style type="text/css">
            {`.home-page-bg-banner {
                background-color: transparent;
              }`}
          </style>
        )}
        <div className={classes.background}>
          <div className={classes.grid}>
            <div className={classes.left}>
              <span className={classes.text}>Let's build amazing technology together.</span>
              <Link to="/discover-your-idea">
                <DiscoverButton />
              </Link>
              <div className={classes.boxesContainer}>
                <a href="#testimonials" className={classes.box}>
                  <img className="icon" src={PeopleIcon} alt="" />
                  <p className={classes.boxText}>
                    People
                    <br />
                    who like us
                  </p>
                  <img className="line" src={Line} alt="" />
                  <img className="arrow" src={Arrow} alt="" />
                </a>
                <Link to="/portfolio" className={classes.box}>
                  <img className="icon" src={StuffIcon} alt="" />
                  <p className={classes.boxText}>
                    Cool tech
                    <br />
                    we built
                  </p>
                  <img className="line" src={Line} alt="" />
                  <img className="arrow" src={Arrow} alt="" />
                </Link>
              </div>
            </div>
            <Hidden smDown>
              {!!projects && !!projects.length && (
                <div className={classes.right}>
                  <Carousel
                    showArrows={false}
                    dynamicHeight
                    infiniteLoop
                    autoPlay
                    stopOnHover
                    showThumbs={false}
                    showIndicators={false}
                    animationHandler={fadeAnimationHandler}
                    swipeable={false}
                    showStatus={false}
                  >
                    {projects.map((project) => {
                      return project.homepageImages.map(({id, image}) => {
                        if (project.isPublished) {
                          return (
                            <div key={`${project.id} - ${id}`} className={classes.imageWrapper}>
                              <Link to={`/project/${project.slug}`}>
                                <img
                                  alt=""
                                  src={image?.fullSize}
                                  style={{height: 'auto', width: '365px'}}
                                />
                              </Link>
                            </div>
                          )
                        }
                        return (
                          <div key={`${project.id} - ${id}`} className={classes.imageWrapper}>
                            <img
                              alt=""
                              src={image?.fullSize}
                              style={{height: '60vh', width: 'auto'}}
                            />
                          </div>
                        )
                      })
                    })}
                  </Carousel>
                </div>
              )}
            </Hidden>
          </div>
        </div>
        <div />
        <ClientBar />
        <MissionStatement />
        <SkillsList skills={skills} />
        <Products />
        <WhoWeServe />
        <RecentProjects />
        {home.ctaform && <CTAForm ctaform={home.ctaform} />}

        <Awards />

        <Testimonials />
        <SeenOn />
      </div>
    )
  }
}

export const fadeAnimationHandler = (props, state) => {
  const transitionTime = props.transitionTime + 'ms'
  const transitionTimingFunction = 'ease-in-out'

  let slideStyle = {
    position: 'absolute',
    display: 'block',
    minHeight: '100%',
    opacity: 0,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    transitionTimingFunction,
    msTransitionTimingFunction: transitionTimingFunction,
    MozTransitionTimingFunction: transitionTimingFunction,
    WebkitTransitionTimingFunction: transitionTimingFunction,
    OTransitionTimingFunction: transitionTimingFunction,
  }

  if (!state.swiping) {
    slideStyle = {
      ...slideStyle,
      WebkitTransitionDuration: transitionTime,
      MozTransitionDuration: transitionTime,
      OTransitionDuration: transitionTime,
      transitionDuration: transitionTime,
      msTransitionDuration: transitionTime,
    }
  }

  return {
    slideStyle,
    selectedStyle: {
      ...slideStyle,
      zIndex: 999,
      opacity: 1,
      position: 'relative',
    },
    prevStyle: {...slideStyle},
  }
}

Home.displayName = 'Home'

function mapStateToProps(state) {
  return {
    ...state.home,
    skills: state.skills,
    feed: state.feed,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getHome, getSkillsList}, dispatch)
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Home)
