import React from 'react'
import {Link} from 'react-router'

import LazyImage from '../common/LazyImage'
import {retinaOrNormalSrc} from '../utils/ImageUtils.js'

class ProjectItem extends React.Component {
  render() {
    const {project} = this.props
    return (
      <Link
        to={`/project/${project.slug}`}
        className="projectsPage__list__item"
        style={{backgroundColor: project.primaryColor}}
      >
        <strong>{project.title}</strong>
        <span>{project.tagline}</span>
        <p>{project.description}</p>
        <LazyImage src={retinaOrNormalSrc(project.mockup)} alt={`${project.title} mockup`} />
      </Link>
    )
  }
}

ProjectItem.displayName = 'ProjectItem'

export default ProjectItem
