import React from 'react'
import {IndexRoute, Route} from 'react-router'

import App from './App.jsx'
import CareerDetails from './careers/CareerDetails.jsx'
import Careers from './careers/Careers.jsx'
import CaseStudiesList from './case_studies/CaseStudiesList.jsx'
import CaseStudyDetails from './case_studies/CaseStudyDetails.jsx'
import Home from './home/Home.jsx'
import InnovationDetails from './innovations/InnovationDetails.jsx'
import Innovations from './innovations/Innovations.jsx'
import AboutUs from './pages/AboutUs.jsx'
import ContactUs from './pages/ContactUs.jsx'
import Culture from './pages/Culture.jsx'
import DiscoverYourIdea from './pages/DiscoverYourIdea.jsx'
import Page from './pages/Page.jsx'
import Privacy from './pages/Privacy.jsx'
import WhatWeDo from './pages/WhatWeDo.jsx'
import Portfolio from './portfolio/Portfolio.jsx'
import ProjectDetails from './projects/ProjectDetails.jsx'
import Projects from './projects/Projects.jsx'
import SkillDetails from './skills/SkillDetails.jsx'

export default function routing() {
  return (
    <Route path="/" component={App}>
      <IndexRoute component={Home} />
      <Route path="/services" component={WhatWeDo} />
      <Route path="/services/:slug" component={SkillDetails} />
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/about-us" component={AboutUs} />
      <Route path="/projects" component={Projects} />
      <Route path="/project/:slug" component={ProjectDetails} />
      <Route path="/innovations" component={Innovations} />
      <Route path="/innovations/:slug" component={InnovationDetails} />
      <Route path="/culture" component={Culture} />
      <Route path="/careers" component={Careers} />
      <Route path="/careers/:slug" component={CareerDetails} />
      <Route path="/portfolio" component={Portfolio} />
      <Route path="/privacy-policy" component={Privacy} />
      <Route path="/case-studies" component={CaseStudiesList} />
      <Route path="/case-study/:slug" component={CaseStudyDetails} />
      <Route path="/discover-your-idea" component={DiscoverYourIdea} />
      {/* should always be at the end */}
      <Route path="/:slug" component={Page} />
    </Route>
  )
}
