import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import BasePage from '../common/BasePage.jsx'
import CaseStudyCTAForm from './CaseStudyCTAForm.jsx'
import {getCaseStudy} from './redux/actions.js'

class CaseStudyDetails extends React.Component {
  componentWillMount() {
    this.props.getCaseStudy(this.props.params.slug, {expand: 'sections,ctaform'})
  }

  render() {
    const {caseStudy, isFetching, notFound} = this.props

    return (
      <BasePage
        className="caseStudyDetails"
        page={caseStudy}
        isFetching={isFetching}
        notFound={notFound}
      >
        {caseStudy && (
          <div>
            <Helmet>
              <title>{caseStudy.title}</title>
              <link rel="canonical" href={`https://tsl.io/case-study/${caseStudy.slug}`} />
              <meta property="og:url" content={`https://tsl.io/case-study/${caseStudy.slug}`} />
            </Helmet>
            <div className="caseStudyDetails__sections">
              <div className="row container">
                <div className="small-12 columns caseStudyDetails__sections__item caseStudyDetails__sections__item--intro">
                  <div
                    className="caseStudyDetails__sections__item__body"
                    dangerouslySetInnerHTML={{__html: caseStudy.introduction}}
                  />
                </div>

                {caseStudy.sections.map((section) => {
                  return (
                    <div
                      className="small-12 columns caseStudyDetails__sections__item"
                      key={section.id}
                    >
                      <h2>{section.title}</h2>
                      <div
                        className="caseStudyDetails__sections__item__body"
                        dangerouslySetInnerHTML={{__html: section.body}}
                      />
                    </div>
                  )
                })}
              </div>
            </div>

            {caseStudy.ctaform && <CaseStudyCTAForm ctaform={caseStudy.ctaform} />}
          </div>
        )}
      </BasePage>
    )
  }
}

CaseStudyDetails.displayName = 'CaseStudyDetails'

function mapStateToProps(state) {
  return {...state.caseStudies}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getCaseStudy}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseStudyDetails)
