import * as Types from './actions.types'

function createInitialState() {
  return {
    list: [],
    isFetching: false,
    notFound: false,
  }
}

export default function tags(state = createInitialState(), action) {
  switch (action.type) {
    case Types.TAGS_LIST_GET:
      return {
        ...createInitialState(),
        list: [],
        isFetching: true,
      }
    case Types.TAGS_LIST_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        notFound: false,
        list: action.tags,
      }
    case Types.TAGS_LIST_GET_FAILURE:
      return {
        ...state,
        list: [],
        isFetching: false,
        notFound: true,
      }
    default:
      return state
  }
}
